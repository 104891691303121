import AlertMessage, {
    showMessage,
  } from "components/alertmessages/Alertmessages"  
  import React, { Suspense, lazy, useEffect, useState } from "react"
  import { Link, useLocation, useNavigate } from "react-router-dom"
  import {
    Container,
    Spinner,
    Button,
    Modal,
    ModalBody,
    ModalHeader,
  } from "reactstrap"
  import "../../../../src//styles/errorfield.scss"
  import { FileUpload } from "helpers/file_uploads/upload_handler"
  import { loginUserData, userId } from "helpers/userId"
  import { useHistory } from "react-router-dom"
  const initialFormDetails = {
    dl_docName: "",
    dl_docType: "",
    dl_docSubtype: "",
    st_file: "",
    regionsList: [],
    SprintToolUsersList: [],
    SprintToolNoList: [],
    ListofTasks: [],
  }

  const ListofTasks = lazy(() =>
    import("components/Customers/SprintTool/ListofTasks")
  )
  
  const AddFeatureSprint = () => {
   
    const [apiStatus, setApiStatus] = useState({ inProgress: false })
    const [errors, setErrors] = useState({})

    const [SprintToolUsersList, setSprintToolUsersList] = useState([]); 
    const [SprintToolNoList, setSprintToolNoList] = useState([]); 
    const [SprintNo, setSprintNo] = useState(""); 
    const [feature_owner, setFeatureOwner] = useState(""); 

    const navigate = useNavigate();
        
      
    async function fetchSprinttoolUserList() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/get_sprint_tool_users_list`
        );
        const data = await response.json();
       // console.log("✅ SprintToolUsersList API Data:", data); // Debugging
        setSprintToolUsersList(Array.isArray(data?.data) ? data.data : []);
      } catch (error) {
        console.log("❌ Error fetching SprintToolUsersList:", error);
        setSprintToolUsersList([]); // Ensure it's always an array
      }
    }

    async function fetchSprinttoolNoList() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/get_sprint_tool_number_list`
        );
        const data = await response.json();
       // console.log("✅ SprintToolNoList API Data:", data); // Debugging
        setSprintToolNoList(Array.isArray(data?.data) ? data.data : []);
      } catch (error) {
        console.log("❌ Error fetching SprintToolNoList:", error);
        setSprintToolNoList([]); // Ensure it's always an array
      }
    } 

useEffect(() => {
  fetchSprinttoolNoList();
  fetchSprinttoolUserList();
}, []);

// Set default values when data is loaded
useEffect(() => {
 // console.log("🔍 SprintToolUsersList Updated:", SprintToolUsersList);
  if (SprintToolUsersList.length > 0 && !feature_owner) {
  //  console.log("✅ Setting feature_owner:", SprintToolUsersList[0]?.stu_uid);
    setFeatureOwner(SprintToolUsersList[0]?.stu_uid || "");
  }
}, [SprintToolUsersList]);

useEffect(() => {
//  console.log("🔍 SprintToolNoList Updated:", SprintToolNoList);
  if (SprintToolNoList.length > 0 && !SprintNo) {
 //   console.log("✅ Setting SprintNo:", SprintToolNoList[0]?.SprintNo);
    setSprintNo(SprintToolNoList[0]?.SprintNo || "");
  }
}, [SprintToolNoList]);

    
  
    // history for navigation
    const history = useNavigate()
  
    // to get api params
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const id = searchParams.get("id")
    const type = searchParams.get("type")
    const SpNo = searchParams.get("SpNo")

    const [form, setForm] = useState({
      feature_no: "", 
      feature_name: "",
      feature_owner: "",
      sprint_no: "",
      status: "",
      department: "",
      priority: "",
      start_date: "",
      end_date: "",
      feature_description: "",
      Notes: "",
    ListofTasks: [],
    SprintToolUsersList: [],
    SprintToolNoList: [],      
    SprintNo: "",
    SprintNo: id ? "" : SpNo || "",
    
  })
  
    // -- Fetch Document Vault list start -- //
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/single_sprint_tool?FeatureNo=${id}`,
        )
  
        if (!response.ok) {
          throw new Error(`Failed to fetch data. Status: ${response.status}`)
        }
  
        const data = await response.json()
  
        if (data?.success) {
          const acData = data?.data[0]
          const totalPoints = data?.totalPoints || 0;
          const SprintToolUsersList = await fetchSprinttoolUserList()
          const SprintToolNoList = await fetchSprinttoolNoList()
          
          
          setForm({
            ...form,
            feature_name: acData.FeatureName,
            feature_owner: acData.FeatureOwner,
            SprintNo: acData.SprintNo,          
            status: acData.Status,
            department: acData.Department,
            priority: acData.Priority,
            start_date: acData.StartDate,
            end_date: acData.EndDate,
            feature_description: acData.FeatureDescription,
            Notes: acData.Notes,  
            SprintToolUsersList, 
            SprintToolNoList,
            totalPoints,         
          })
        } else {
          throw new Error("Data fetching was not successful")
        }
      } catch (err) {
        history("/sprint_list")
      }
    }
  
    useEffect(() => {
      if (id) {
        fetchData()
      }
    }, [id])
  
    useEffect(() => {
      if (!id && SpNo) {
        setForm(prevForm => ({ ...prevForm, SprintNo: SpNo }));
      }
    }, [SpNo, id]);
  
    const handleForm = (e, action, key) => {
        if (e?.target) {
          const { name, value } = e.target;
      
          setForm(prevForm => ({
            ...prevForm,
            [name]: value,
          }));
        }
      
        switch (action) {
          case "addListofTasks": {
            let latestNumber = 2;
            if (form.ListofTasks.length) {
              let numberList = form.ListofTasks.map(each => each.number);
              latestNumber = Math.max(...numberList) + 1;
            }
      
            setForm(prevForm => ({
              ...prevForm,
              ListofTasks: [
                ...prevForm.ListofTasks,
                {
                  number: prevForm.ListofTasks.length ? latestNumber : 1,
                  
                }
              ]
            }));
            break;
          }
      
          case "removeListofTasks": {
            setForm(prevForm => ({
              ...prevForm,
              ListofTasks: prevForm.ListofTasks.filter((_, idx) => idx !== key),
            }));
            break;
          }
      
          case "taskList": {
            if (e?.target) {
              const { name, value } = e.target;
      
              setForm(prevForm => ({
                ...prevForm,
                ListofTasks: prevForm.ListofTasks.map((task, idx) =>
                  idx === key
                    ? {
                        ...task,
                        [name]: value,
                        SprintToolUsersList: task.SprintToolUsersList || [],
                        SprintToolNoList: task.SprintToolNoList || [],
                      }
                    : task
                ),
              }));
            }
            break;
          }
      
          default:
            break;
        }
      };
      
  
  
    const handleImage = async e => {
      const { name, files } = e.target
      const allowedTypes = [
        "image/png",
        "image/jpeg",
        "image/jpg",
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ]
  
      if (files && files.length > 0) {
        const file = files[0]
  
        if (!allowedTypes.includes(file.type)) {
          showMessage(
            "Only images (PNG, JPEG), PDFs, and Word documents are allowed.",
            "error",
          )
          return
        }
  
        try {
          const result = await FileUpload(files)
          setForm({ ...form, [name]: result.files?.[0]?.url })
        } catch (error) {
          showMessage("File upload failed:", "error")
        }
      }
    }
  
    const validateForm = () => {
      var isValid = true
      const newErrors = {}
  
      setErrors(newErrors)
      return isValid
    }
  
    const handleFormSubmit = async () => {
      validateForm();
      
      if (!!form.feature_name) {
        try {
          setApiStatus({ ...apiStatus, inProgress: true });
    
          const url = id
            ? `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/update_feature_tool`
            : `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/add_feature_sprint`;
    
          const data = {
            FeatureNo: id || 0,
            feature_name: form.feature_name,
            feature_owner: form.feature_owner,
            SprintNo: form.SprintNo,
            status: form.status,
            department: form.department,
            priority: form.priority,
            start_date: form.start_date,
            end_date: form.end_date,
            feature_description: form.feature_description,
            Notes: form.Notes,
            ListofTasks: form.ListofTasks.map(eachDocument => ({
              TaskName: eachDocument.TaskName || "",
              //sprint_no: eachDocument.sprint_no || "",
              status: eachDocument.status || "",
              task_owner: eachDocument.task_owner || "",
              priority: eachDocument.priority || "",
              start_date: eachDocument.start_date || "",
              end_date: eachDocument.end_date || "",
              task_description: eachDocument.task_description || "",
              notes: eachDocument.notes || "",
              Points: eachDocument.Points || "",
            })),
          };
    
          const options = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
          };
    
          const response = await fetch(url, options);
          const addOrUpdateDocuments = await response.json();
    
          if (addOrUpdateDocuments?.success === true) {
            showMessage(addOrUpdateDocuments.message, "success");
    
            setTimeout(() => {
              if (type === 1) {
                navigate(`/sprint_list?id=${form.SprintNo}`); // Corrected redirect
              } else {
                navigate(`/sprint_list?id=${form.SprintNo}`); 
              }
            }, 3000);
          } else {
            showMessage(addOrUpdateDocuments?.message, "error", false);
            setApiStatus({ ...apiStatus, inProgress: false });
          }
        } catch (e) {
          showMessage(e, "error", false);
          setApiStatus({ ...apiStatus, inProgress: false });
        }
      }
    };
  
    return (
      <React.Fragment>
        <AlertMessage />
        <div className="page-content">
          <Container fluid>
            <div className="page-title-box">
              <h4 className="font-size-18">ADD Feature SPRINT TOOL</h4>
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/sprint_list">Operation</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/sprint_list">Feature Sprint Tool</Link>
                </li>
              </ol>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="row">
                    <div className="col-md-3">
                        <div className="mb-3 position-relative">
                        <label htmlFor="feature_name" className="form-label">
                            Feature Name <span className="text-danger">*</span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            name="feature_name"
                            id="feature_name"
                            value={form.feature_name}
                            onChange={handleForm}
                        />
                        </div>
                    </div> 
                 
                    <div className="col-md-3">
                        
                    </div> 

                    <div className="col-md-3">
                      <div className="mb-3 position-relative">
                        <label htmlFor="SprintNo" className="form-label">Sprint No</label>
                        <select
                          className="form-select"
                          name="SprintNo"
                          id="SprintNo"
                          value={form.SprintNo || ""}
                          onChange={handleForm}
                        >
                          <option value="">Select Sprint No</option>
                          {SprintToolNoList.length > 0 ? (
                            SprintToolNoList.map(each => (
                              <option value={each.SprintNo} key={each.SprintNo}>
                                {each.SprintNo}
                              </option>
                            ))
                          ) : (
                            <option disabled>Loading Sprint Numbers...</option>
                          )}
                        </select>
                      </div>
                    </div>
                 
                    <div className="col-md-3">
                        <div className="mb-3 position-relative">
                        <label htmlFor="status" className="form-label">
                            Status 
                        </label>
                        <select
                            className="form-select"
                            name="status"
                            id="status"
                            value={form.status}
                            onChange={handleForm}
                        >
                            <option value="" defaultValue>
                            Select Status
                            </option>
                            <option value="Draft">Draft</option>
                            <option value="Ready">Ready</option>
                            <option value="In Progress">In Progress</option>
                            <option value="In Testing">In Testing</option>
                            <option value="Deferred">Deferred</option>
                            <option value="Review">Review</option>
                            <option value="Done">Done</option>
                        </select>
                        </div>
                    </div>

                  <div className="col-md-3">
                    <div className="mb-3 position-relative">
                      <label htmlFor="feature_owner" className="form-label">Feature Owner</label>
                      <select
                        className="form-select"
                        name="feature_owner"
                        id="feature_owner"
                        value={form.feature_owner || ""}
                        onChange={handleForm}
                      >
                        <option value="">Select Feature Owner</option>
                        {SprintToolUsersList.length > 0 ? (
                          SprintToolUsersList.map(each => (
                            <option value={each.stu_uid} key={each.stu_uid}>
                              {each.stu_username}
                            </option>
                          ))
                        ) : (
                          <option disabled>Loading Feature Owners...</option>
                        )}
                      </select>
                    </div>
                  </div>
                    
                    <div className="col-md-3">
                        <div className="mb-3 position-relative">
                        <label htmlFor="department" className="form-label">
                            Department
                        </label>          
                        <select
                            className="form-select"
                            name="department"
                            id="department"
                            value={form.department}
                            onChange={handleForm}
                        >
                            <option value="" defaultValue>
                            Select Department
                            </option>
                            <option value="IT">IT</option>
                            <option value="HR">HR</option>
                            <option value="Sales">Sales</option>
                            <option value="Operations">Operations</option>
                            <option value="Advertisements">Advertisements</option>
                            <option value="Branding">Branding</option>
                            <option value="Realtor Services">Realtor Services</option>
                            <option value="Finance">Finance</option>
                        </select>
                        </div>
                    </div>
                    
                    <div className="col-md-3">
                        <div className="mb-3 position-relative">
                        <label htmlFor="start_date" className="form-label">
                            Start Date
                        </label>
                        <input
                            type="date"
                            className="form-control"
                            name="start_date"
                            id="start_date"
                            value={form.start_date}
                            onChange={handleForm}
                        />
                        </div>
                    </div>
                    
                    <div className="col-md-3">
                        <div className="mb-3 position-relative">
                        <label htmlFor="end_date" className="form-label">
                            End Date
                        </label>
                        <input
                            type="date"
                            className="form-control"
                            name="end_date"
                            id="end_date"
                            value={form.end_date}
                            onChange={handleForm}
                        />
                        </div>
                    </div>                     
                    
                    <div className="col-md-6">
                        <div className="mb-3 position-relative">
                        <label htmlFor="feature_description" className="form-label">
                            Feature Description
                        </label>
                        <textarea
                            className="form-control"
                            name="feature_description"
                            id="feature_description"
                            rows="3"
                            value={form.feature_description}
                            onChange={handleForm}
                        ></textarea>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="mb-3 position-relative">
                        <label htmlFor="priority" className="form-label">
                            Priority 
                        </label>
                        <select
                            className="form-select"
                            name="priority"
                            id="priority"
                            value={form.priority}
                            onChange={handleForm}
                        >
                            <option value="" defaultValue>
                            Select Priority
                            </option>
                            <option value="Low">Low</option>
                            <option value="Medium">Medium</option>
                            <option value="High">High</option>
                            <option value="Critical">Critical</option>
                        </select>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="mb-3 position-relative">
                        <label htmlFor="points" className="form-label">
                          Points 
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            name="points"
                            id="points"
                            value={form.totalPoints}
                            onChange={handleForm}
                            disabled
                        />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="mb-3 position-relative">
                        <label htmlFor="Notes" className="form-label">
                            Notes
                        </label>
                        <textarea
                            className="form-control"
                            name="Notes"
                            id="Notes"
                            rows="3"
                            value={form.Notes}
                            onChange={handleForm}
                        ></textarea>
                        </div>
                    </div>

                

             <Suspense
                                          fallback={
                                            <div className="text-center m-4">Loading...</div>
                                          }
                                        >
                                          <h4 className="page-title-box">
                                             List of Tasks 
                                          </h4>
            
                                          <ListofTasks
                                            form={form}
                                            handleForm={handleForm}                                            
                                            FeatureNo={id}
                                            SprintToolNoList={SprintToolNoList}
                                            SprintToolUsersList={SprintToolUsersList}
                                            
                                           
                                          />
                                        </Suspense>


                                        </div>
             
             </div>
           </div>                            
  
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    {apiStatus.inProgress ? (
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light me-1"
                        disabled={true}
                      >
                        <Spinner color="light" size="sm">
                          Loading ...
                        </Spinner>
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light me-1"
                        onClick={handleFormSubmit}
                      >
                        {id ? "Save" : "Submit"}
                      </button>
                    )}
                    &nbsp; &nbsp;
                    <Link
                      to={type == 1 ? "/tile_list" : "/tile_list"}
                      className="btn btn-secondary waves-effect"
                    >
                      Cancel
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    )
  }
  
  export default AddFeatureSprint
  