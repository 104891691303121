import React, { useEffect, useState } from "react"

const { MDBDataTable } = require("mdbreact")
const { Row, Col, Card, CardBody, CardTitle } = require("reactstrap")
import "../../styles/datatables.scss"
import { Link } from "react-router-dom"
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"

const CustomersListTable = () => {
  const [membersInformation, setMembersInformation] = useState({ data: [] })

  useEffect(() => {
    const fetchData = async () => {
      const user_id =
        JSON.parse(localStorage.getItem("userData")).user.user_id || 0
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/customers_list`
      )
      const data = await response.json()
      setMembersInformation({ ...membersInformation, data: data?.data || [] })
    }
    fetchData()
  }, [])

  const handleRemoveRole = async id => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/delete_member_information`
      const userData = JSON.parse(localStorage.getItem("userData"))
      const data = {
        user_id: userData?.user?.user_id,
        user_id: id,
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options, {})
      const removeData = await response.json()

      if (removeData?.success === true) {
        setMembersInformation({
          ...membersInformation,
          data: membersInformation?.data?.filter(each => each.user_id !== id),
        })

        showMessage(removeData.message, "success")
      } else {
        showMessage(removeData.message, "error", false)
      }
    } catch (error) {
      showMessage(removeData.message, "error", false)
    }
  }

  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },      
      {
        label: "FullName",
        field: "name",
        sort: "asc",
        width: 270,
      },
      {
        label: "Email",
        field: "user_email",
        sort: "asc",
        width: 200,
      },
      {
        label: "Phone",
        field: "user_phone",
        sort: "asc",
        width: 100,
      },
      {
        label: "Country",
        field: "country",
        sort: "asc",
        width: 150,
      },
      {
        label: "Role",
        field: "userrole",
        sort: "asc",
        width: 150,
      },      
    ],
    rows: membersInformation?.data?.map((each, index) => {
      return {
        sno: <div>{index + 1} </div>,        
        relationship: each?.ac_reliation || "",
        name: each?.user_fname + " " + each?.user_lname || "",
        user_email: each?.user_email || "",
        user_phone: each?.user_phone || "",
        country: each?.country || "",
        userrole: 
        
        each?.user_role === "0" || each?.user_role === 0
      ? "Customer"
      : each?.user_role === "1" || each?.user_role === 1
      ? "Admin"
      : each?.user_role === "2" || each?.user_role === 2
      ? "Operation"
      : each?.user_role === "3" || each?.user_role === 3
      ? "Business Partner"
              : "",       
      }
    }),
  }

  return (
    <React.Fragment>
      <AlertMessage />
      <Row>
      <Col className="col-12">
         <div className="page-title-box">
            <h4 className="font-size-18">USERS LIST</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="/">User List</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/">Users List</a>
              </li>
            </ol>
          </div>
        
          <Card>
            <CardBody>
              
              <div className="col-12 d-flex">
                <div>
                  <Link
                    to="/add-user-information"
                    className="btn btn-danger my-2"
                    type="button"
                  >
                    Add User
                  </Link>
                </div>               
              </div>
              <MDBDataTable
                responsive
                bordered
                data={data}
                noBottomColumns={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CustomersListTable
