import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
const { MDBDataTable } = require("mdbreact")
const { Row, Col, Card, CardBody, CardTitle } = require("reactstrap")
import "../../styles/datatables.scss"
import { Link } from "react-router-dom"
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"



const TaskSprintTable = () => {
  const [featurelist, setFeatureList] = useState({ data: [] })

  
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const id = searchParams.get("id")

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/get_feature_list?id=${id}`
      )

      const data = await response.json()
      
      setFeatureList({ ...featurelist, data: data?.data || [] })
    }
    fetchData()
  }, [])

  const handleRemoveRole = async id => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/delete_feature_list`
      const userData = JSON.parse(localStorage.getItem("userData"))
      const data = {        
        FeatureNo: id,
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options, {})
      const removeData = await response.json()

      if (removeData?.success === true) {
        setFeatureList({
          ...featurelist,
          data: featurelist?.data?.filter(each => each.FeatureNo !== id),
        })

        showMessage(removeData.message, "success")
      } else {
        showMessage(removeData.message, "error", false)
      }
    } catch (error) {
      showMessage(removeData.message, "error", false)
    }
  }

  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "Edit",
        field: "edit",
        sort: "asc",
        width: 150,
      },
      {
        label: "Feature No",
        field: "featureno",
        sort: "asc",
        width: 270,
      },
      {
        label: "Feature Name",
        field: "featurename",
        sort: "asc",
        width: 200,
      },
      {
        label: "Feature Owner",
        field: "featureowner",
        sort: "asc",
        width: 200,
      },
      {
        label: "Priority",
        field: "priority",
        sort: "asc",
        width: 200,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 200,
      },
      {
        label: "Start Date",
        field: "startdate",
        sort: "asc",
        width: 200,
      },
      {
        label: "End Date",
        field: "enddate",
        sort: "asc",
        width: 200,
      },
      {
        label: "No. of Points",
        field: "noofpoints",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: featurelist?.data?.map((each, index) => {
      return {
        sno: <div>{index + 1} </div>,
        edit:
          (
            <Link
              to={`/add_feature_sprint?id=${each.FeatureNo}&type=0`}
              className="btn btn-outline-primary btn-sm edit"
              title="Edit"
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>
          ) || "",
        featureno: each?.FeatureNo,
        featurename: each?.FeatureName,
        featureowner: each?.FeatureOwner,
        status: each?.Status,
        priority: each?.Priority,
        startdate: each?.StartDate,
        enddate: each?.EndDate,
        noofpoints: each?.totalPoints,
        action:
          (
            <Col xl={3} lg={4} sm={6}>
              <i
                className="ion ion-md-trash"
                title="Delete"
                type="button"
                onClick={() =>
                  each.FeatureNo &&
                  window.confirm(
                    `Are you sure do you want to delete ${each.FeatureName}?`
                  ) &&
                  handleRemoveRole(each.FeatureNo)
                }
              ></i>
            </Col>
          ) || "",
      }
    }),
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="h4">Feature List</CardTitle>
              <div className="col-12 d-flex button-toolbar">
                <div>
                  <Link
                    to={`/add_feature_sprint/?SpNo=${id}`}
                    className="btn btn-danger my-2"
                    type="button"
                >
                    Add Feature
                </Link>
                

                &nbsp; &nbsp;
                <Link
                    to="/tile_list"
                    className="btn btn-danger my-2"
                    type="button"
                >
                    Back to Tile
                </Link>
                </div>
                

                {/* <div>
                  <Link to="/generated_will_documet_list" className="btn btn-danger my-2" type="button">
                    Generate a Will Document
                  </Link> &nbsp; &nbsp;
                </div>                 */}

              </div>
              <MDBDataTable
                responsive
                bordered
                data={data}
                noBottomColumns={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default TaskSprintTable

   

export const ListOfExistingTasks = ({ FeatureNo }) => {
  const [ListofTasks, setListOfTasks] = useState({ data: [] })

  useEffect(() => {
    async function fetchData() {
      try {
        const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/get_tasks_list?FeatureNo=${FeatureNo}`

        const response = await fetch(url)
         
        const data = await response.json()
      
        setListOfTasks({ ...ListofTasks, data: data?.data || [] })        
     
      } catch (err) {}
    }

    fetchData()
  }, [FeatureNo])

  const handleRemoveRole = async id => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/delete_sprint_task`
      const data = {
        TaskNo: id,
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options, {})
      const removeData = await response.json()

      if (removeData?.success === true) {
        setListOfTasks({
          ...ListofTasks,
          data: ListofTasks?.data?.filter(each => each.TaskNo !== id),
        })

        showMessage(removeData.message, "success")
      } else {
        showMessage(removeData.message, "error", false)
      }
    } catch (error) {
      showMessage(removeData?.message || "Something went wrong", "error", false)
    }
  }

  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "Edit",
        field: "edit",
        sort: "asc",
        width: 150,
      },
      {
        label: "TaskNo",
        field: "taskno",
        sort: "asc",
        width: 270,
      },
      {
        label: "Task Name",
        field: "taskname",
        sort: "asc",
        width: 200,
      },
      {
        label: "Task Owner",
        field: "Taskowner",
        sort: "asc",
        width: 200,
      },        
      {
        label: "Priority",
        field: "priority",
        sort: "asc",
        width: 200,
      }, 
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 200,
      }, 
      {
        label: "Start Date",
        field: "startdate",
        sort: "asc",
        width: 200,
      }, 
      {
        label: "End Date",
        field: "enddate",
        sort: "asc",
        width: 200,
      }, 
      {
        label: "No. of Points",
        field: "Points",
        sort: "asc",
        width: 200,
      },     
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: ListofTasks?.data?.map((each, index) => {
      return {
        sno: <div>{index + 1} </div>,
        edit:
          (
            <Link
              to={`/edit_sprint_task?id=${each.TaskNo}&type=0`}
              className="btn btn-outline-primary btn-sm edit"
              title="Edit"
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>
          ) || "",
        taskno: each?.TaskNo, 
        taskname: each?.TaskName, 
        priority: each?.Priority, 
        status: each?.Status, 
        Taskowner: each?.TaskOwner, 
        startdate: each?.StartDate, 
        enddate: each?.EndDate,
        Points: each?.Points,       
        action:
          (
            <Col xl={3} lg={4} sm={6}>
              <i
                className="ion ion-md-trash"
                title="Delete"
                type="button"
                onClick={() =>
                  each.TaskNo &&
                  window.confirm(
                    `Are you sure do you want to delete ${each?.TaskName}?`
                  ) &&
                  handleRemoveRole(each.TaskNo)
                }
              ></i>
            </Col>
          ) || "",
      }
    }),
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <MDBDataTable
                responsive
                bordered
                data={data}
                noBottomColumns={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}
