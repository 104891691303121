import React, { useEffect, useState } from "react"
const { MDBDataTable } = require("mdbreact")
const { Row, Col, Card, CardBody, CardTitle } = require("reactstrap")
import "../../styles/datatables.scss"
import { Link } from "react-router-dom"
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"
import { loginUserData, userId } from "helpers/userId"

const SprintToolsListTables = () => {
  const [sprint, setSprintTool] = useState({ data: [] })

  useEffect(() => {
    const fetchData = async () => {
      const user_id =
        JSON.parse(localStorage.getItem("userData")).user.user_id || 0
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/single_realtorservice`,
      )
      const data = await response.json()
      setSprintTool({ ...sprint, data: data?.data || [] })
    }
    fetchData()
  }, [])

  const handleRemoveRole = async id => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/delete_realtorservice`
      const userData = JSON.parse(localStorage.getItem("userData"))
      const data = {
        rsl_id: id,
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options, {})
      const removeData = await response.json()

      if (removeData?.success === true) {
        setDocumentVault({
          ...documents,
          data: documents?.data?.filter(each => each.rsl_id !== id),
        })

        showMessage(removeData.message, "success")
      } else {
        showMessage(removeData.message, "error", false)
      }
    } catch (error) {
      showMessage(removeData.message, "error", false)
    }
  }

  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "Edit",
        field: "edit",
        sort: "asc",
        width: 150,
      },
      {
        label: "Property Name",
        field: "SprintName",
        sort: "asc",
        width: 270,
      },
      {
        label: "Customer Name",
        field: "StoryOwner",
        sort: "asc",
        width: 200,
      },
      {
        label: "Status",
        field: "StoryStatus",
        sort: "asc",
        width: 100,
      },      
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: sprint?.data?.map((each, index) => ({
      sno: <div>{index + 1}</div>,
      edit: (
        <Link
          to={`/add_realtor_serives?id=${each.rsl_id}`}
          className="btn btn-outline-primary btn-sm edit"
          title="Edit"
        >
          <i className="fas fa-pencil-alt"></i>
        </Link>
      ),
      SprintName: each.property_name || "",
      StoryOwner: each.customer_name || "Not Assigned",
      StoryStatus: each.status || "",      
      action: (
        <div style={{ display: "flex", gap: "15px" }}>
          <i
            className="ion ion-md-trash"
            title="Delete"
            type="button"
            onClick={() =>
              each.rsl_id &&
              window.confirm(`Are you sure you want to delete ${each.property_name}?`) &&
              handleRemoveRole(each.rsl_id)
            }
          ></i>          
        </div>
      ),
    })),
  }

  return (
    <React.Fragment>
      <AlertMessage />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="h4">Realtor Services List</CardTitle>
              <div className="col-12 d-flex">
                <div>
                  <Link
                    to="/add_realtor_serives"
                    className="btn btn-danger my-2"
                    type="button"
                  >
                    Add Realtor Services
                  </Link>
                  
                </div>
              </div>
              <MDBDataTable
                responsive
                bordered
                data={data}
                noBottomColumns={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default SprintToolsListTables
