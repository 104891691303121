import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import default_pic from "../../../assets/images/users/default.jpg";
// users
import user1 from "../../../assets/images/users/user-4.jpg"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const [username, setusername] = useState("Admin");
  const [profilePic, setProfilePic] = useState(default_pic);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  useEffect(() => {
    // console.log("user nmnot");
    const {profile_pic , user_fname, user_lname } = JSON.parse(localStorage.getItem("userData")).user;
    if(profile_pic) {
      setProfilePic(profile_pic);
    }

    if (user_fname) {
      setFirstName(user_fname);
    }
    if (user_lname) {
      setLastName(user_lname);
    }
    
  }, [])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={profilePic}
            alt="Header Avatar"
            title={`${firstName} ${lastName}`}
          />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">           
        <a href="#" tabindex="0" role="menuitem" class="dropdown-item"> 
        <i class="mdi mdi-account-circle font-size-17 align-middle me-1"></i>{firstName} {lastName} </a>    
          <div className="dropdown-divider" />          
          <Link to="/logout" className="dropdown-item text-danger">
            <i className="bx bx-power-off font-size-17 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
