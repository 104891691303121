import AlertMessage, {
    showMessage,
  } from "components/alertmessages/Alertmessages"
  import { userId } from "helpers/userId"  
  import React, { memo, useEffect, useState } from "react"
  import { useNavigate, useLocation } from "react-router-dom"
  import { Container, Form, Button, Spinner } from "reactstrap"
  
  const intialForm = {
    task_name: "",
    sprint_no: "",
    status: "",
    task_owner: "",
    priority: "",
    start_date: "",
    end_date: "",
    task_description: "",
    notes: "",
    Points: "",
    SprintToolUsersList: [],
    SprintToolNoList: [],
  }
  
  const EditSprintTask = () => {
    const [form, setForm] = useState(intialForm)
    // history for navigation
    const history = useNavigate()
    const [apiStatus, setApiStatus] = useState({ inProgress: false })
  
    // to get api params
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const id = searchParams.get("id")
    
  
    async function fetchSprinttoolUserList() {
         try {
           const response = await fetch(
             `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/get_sprint_tool_users_list`,
           )
           const data = await response.json()
           setForm({ ...form, SprintToolUsersList: data?.data || [] })
     
           return data?.data
         } catch (error) {
           console.log(error)
         }
       }
     
       useEffect(() => {
         fetchSprinttoolUserList()
       }, [])
   
       async function fetchSprinttoolNoList() {
           try {
             const response = await fetch(
               `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/get_sprint_tool_number_list`,
             )
             const data = await response.json()
             setForm({ ...form, SprintToolNoList: data?.data || [] })
       
             return data?.data
           } catch (error) {
             console.log(error)
           }
         }
       
         useEffect(() => {
           fetchSprinttoolNoList()
         }, [])
  
    const handleForm = async (e, action, key) => {
      let { name, value, type, checked } = e?.target || {}  
      
        setForm({ ...form, [name]: value })     
    }
  
    const handleFormSubmit = async () => {
      try {
        setApiStatus({ ...apiStatus, inProgress: true })
        const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/update_single_task_details`
  
        const data = {
        TaskNo: id || 0,
        TaskName: form.TaskName || "",
        SprintNo: form.SprintNo || "",
        Status: form.Status || "",
        TaskOwner: form.TaskOwner || "",
        Priority: form.Priority || "",
        StartDate: form.StartDate || "",
        EndDate: form.EndDate || "",
        TaskDescription: form.TaskDescription || "",
        Notes: form.Notes || "",
        Points: form.Points || "",
        }
  
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
  
        const response = await fetch(url, options, {})
  
        const updateOwnersList = await response.json()
  
        if (updateOwnersList?.success === true) {
          setApiStatus({ ...apiStatus, inProgress: false })
          showMessage(updateOwnersList.message, "success")
  
          setTimeout(() => {
            history(-1)
          }, [2000])
        } else {
          showMessage(updateOwnersList?.message, "error", false)
          setApiStatus({ ...apiStatus, inProgress: false })
        }
      } catch (e) {
        showMessage(updateOwnersList?.message, "error", false)
      }
    }

   
    useEffect(() => {
      async function fetchData() {
        try {
          const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/get_single_task_details?TaskNo=${id}`
  
          const response = await fetch(url)
  
          const data = await response.json()
  
          if (data?.success) {
            
            const SprintToolUsersList = await fetchSprinttoolUserList()
            const SprintToolNoList = await fetchSprinttoolNoList()

            const decryptedData = data?.data?.map(each => {              
              return {
                ...each,               
                SprintToolUsersList: SprintToolUsersList,
                SprintToolNoList: SprintToolNoList,
              }
            })
  
            const resultData = decryptedData[0]
  
            setForm({ ...form, ...resultData })
          }
        } catch (err) {
          console.log(err)
        }
      }
  
      fetchData()
    }, [])
   
    useEffect(() => {
        if (form.SprintToolUsersList.length > 0 && !form.TaskOwner) {
          setForm(prev => ({
            ...prev,
            TaskOwner: form.SprintToolUsersList[0].stu_uid, // Set a default if necessary
          }));
        }
      }, [form.SprintToolUsersList]);
  
      useEffect(() => {
          if (form.SprintToolNoList.length > 0 && !form.SprintNo) {
            setForm(prev => ({
              ...prev,
              SprintNo: form.SprintToolNoList[0].SprintNo, // Set a default if necessary
            }));
          }
        }, [form.SprintToolNoList]);



    return (
      <React.Fragment>
        <div className="page-content">
          <AlertMessage />
          <Container fluid>
          <div className="page-title-box">
              <h4 className="font-size-18">EDIT Task</h4>
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <a href="/">Operation</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/">Edit Task</a>
                </li>
              </ol>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
  
                      <Form className="my-4">
                        <div className="row">
                         
                         {/* Task Name */}
                        <div className="col-md-3">
                        <div className="mb-3 position-relative">
                            <label htmlFor="task_name" className="form-label">Task Name</label>
                            <input
                            type="text"
                            className="form-control"
                            name="TaskName"
                            id="task_name"
                            placeholder="Task Name"
                            onChange={(e) => handleForm(e, "task")}
                            value={form.TaskName}
                            />
                        </div>
                        </div>

                        {/* Sprint No (Dropdown) */}
                        <div className="col-md-3">
                        <div className="mb-3 position-relative">
                            <label htmlFor="sprint_no" className="form-label">Sprint No</label>
                            <select
                            className="form-select"
                            name="SprintNo"
                            id="sprint_no"
                            onChange={(e) => handleForm(e, "task")}
                            value={form.SprintNo}
                            >
                            <option value="">Select Sprint No</option>
                            {form.SprintToolNoList.map(sprint => (
                                <option value={sprint.SprintNo} key={sprint.SprintNo}>
                                {sprint.SprintNo}
                                </option>
                            ))}
                            </select>
                        </div>
                        </div>

                        {/* Status */}
                        <div className="col-md-3">
                        <div className="mb-3 position-relative">
                            <label htmlFor="status" className="form-label">Status</label>
                            <select
                            className="form-select"
                            name="Status"
                            id="status"
                            onChange={(e) => handleForm(e, "task")}
                            value={form.Status}
                            >
                            <option value="">Select Status</option>
                            <option value="Draft">Draft</option>
                            <option value="Ready">Ready</option>
                            <option value="In Progress">In Progress</option>
                            <option value="In Testing">In Testing</option>
                            <option value="Deferred">Deferred</option>
                            <option value="Review">Review</option>
                            <option value="Done">Done</option>
                            </select>
                        </div>
                        </div>

                        {/* Task Owner */}
                        <div className="col-md-3">
                        <div className="mb-3 position-relative">
                            <label htmlFor="task_owner" className="form-label">Task Owner</label>
                            <select
                            className="form-select"
                            name="TaskOwner"
                            id="task_owner"
                            onChange={(e) => handleForm(e, "task")}
                            value={form.TaskOwner}
                            >
                            <option value="">Select Task Owner</option>
                            {form.SprintToolUsersList.map(user => (
                                <option value={user.stu_uid} key={user.stu_uid}>
                                {user.stu_username}
                                </option>
                            ))}
                            </select>
                        </div>
                        </div>

                        {/* Priority */}
                        <div className="col-md-3">
                        <div className="mb-3 position-relative">
                            <label htmlFor="priority" className="form-label">Priority</label>
                            <select
                            className="form-select"
                            name="Priority"
                            id="priority"
                            value={form.Priority}
                            onChange={(e) => handleForm(e, "task")}
                            >
                            <option value="">Select Priority</option>
                            <option value="Low">Low</option>
                            <option value="Medium">Medium</option>
                            <option value="High">High</option>
                            <option value="Critical">Critical</option>
                            </select>
                        </div>
                        </div>

                        {/* Start Date */}
                        <div className="col-md-3">
                        <div className="mb-3 position-relative">
                            <label htmlFor="start_date" className="form-label">Start Date</label>
                            <input
                            type="date"
                            className="form-control"
                            name="StartDate"
                            id="start_date"
                            onChange={(e) => handleForm(e, "task")}
                            value={form.StartDate}
                            />
                        </div>
                        </div>

                        {/* End Date */}
                        <div className="col-md-3">
                        <div className="mb-3 position-relative">
                            <label htmlFor="end_date" className="form-label">End Date</label>
                            <input
                            type="date"
                            className="form-control"
                            name="StartDate"
                            id="end_date"
                            onChange={(e) => handleForm(e, "task")}
                            value={form.StartDate}
                            />
                        </div>
                        </div>

                        {/* Points */}
                        <div className="col-md-3">
                        <div className="mb-3 position-relative">
                            <label htmlFor="Points" className="form-label">Points</label>
                            <input
                            type="text"
                            className="form-control"
                            name="Points"
                            id="Points"
                            placeholder="Points"
                            onChange={(e) => handleForm(e, "task")}
                            value={form.Points}
                            />
                        </div>
                        </div>

                        {/* Task Description */}
                        <div className="col-md-6">
                        <div className="mb-3 position-relative">
                            <label htmlFor="task_description" className="form-label">Task Description</label>
                            <textarea
                            className="form-control"
                            name="TaskDescription"
                            id="task_description"
                            rows="3"
                            placeholder="Enter task description"
                            onChange={(e) => handleForm(e, "task")}
                            value={form.TaskDescription}
                            ></textarea>
                        </div>
                        </div>

                        {/* Notes */}
                        <div className="col-md-6">
                        <div className="mb-3 position-relative">
                            <label htmlFor="notes" className="form-label">Notes</label>
                            <textarea
                            className="form-control"
                            name="Notes"
                            id="notes"
                            rows="3"
                            placeholder="Enter notes"
                            onChange={(e) => handleForm(e, "task")}
                            value={form.Notes}
                            ></textarea>
                        </div>
                        </div>

                        </div>
                      </Form>
                      
                  </div>
                </div>
              </div>           
            </div>
  
            
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    {apiStatus.inProgress ? (
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light me-1"
                        disabled={true}
                      >
                        <Spinner color="light" size="sm">
                          Loading ...
                        </Spinner>
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light me-1"
                        onClick={handleFormSubmit}
                      >
                        Save
                      </button>
                    )}
                    &nbsp; &nbsp;
                    <Button
                      type="button"
                      className="btn btn-secondary waves-effect"
                      onClick={() => {
                        history(-1)
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    )
  }
  
  export default memo(EditSprintTask)
  