import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"
import { decryptData } from "pages/Utility/enrypt_decrypt/crypto.secure"
import React, { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import {
  Container,
  Spinner,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"
import "../../../../src//styles/errorfield.scss"
import { FileUpload } from "helpers/file_uploads/upload_handler"
import { loginUserData, userId } from "helpers/userId"
import EmailTemplate from "./EmailTemplate"

const initialFormDetails = {  
  sd_role_id: "",
  sd_subject: "",
}

const AddEmail = () => {
  const [form, setForm] = useState(initialFormDetails)
  const [apiStatus, setApiStatus] = useState({ inProgress: false })

  const [errors, setErrors] = useState({})

  const [showModal, setShowModal] = useState(false)
  const [selectedId, setSelectedId] = useState("")
  const history = useNavigate()

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get("id")
  const type = searchParams.get("type")
  const temp = searchParams.get("temp")

  const [imageName, setImageName] = useState("");
  
  const handlePreviewClick = (e, id) => {
    e.preventDefault()
    setSelectedId(id)
    setShowModal(true)
  }

  const handleClose = () => {
    setShowModal(false)
    setSelectedId(null)
  }
  
  const fetchData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/single_email_temp?sd_id=${id}`
      );
      const data = await response.json();
  
      
  
      if (data?.success && data?.data?.length > 0) {
        const acData = data.data[0];  // ✅ Now safely extract first object
  
        setForm((prevForm) => ({
          ...prevForm,
          sd_tem_name: temp || "",
          sd_role_id: acData?.sd_role_id || "",
          sd_cc_email: acData?.sd_cc_email || "",
          sd_from_email: acData?.sd_from_email || "",
          sd_subject: acData?.sd_subject || "",
          sd_message: acData?.sd_message || "",
          sd_image: acData?.sd_image || "",
          sd_button_name: acData?.sd_button_name || "",
          sd_button: acData?.sd_button || "",
          sd_flog: acData?.sd_flog || "",
        }));
  
       
      } else {
        console.warn("No data found in API response");
      }
    } catch (err) {
      console.error("Error fetching data:", err);
      history("/email_list");
    }
  };
  
  useEffect(() => {
    if (id) {
      fetchData()
    }
  }, [])

  // -- Fetch Document Vault list end -- //

  const handleForm = (e) => {
    if (typeof e === "string") {
      // ReactQuill handler
      setForm((prevForm) => ({
        ...prevForm,
        sd_message: e,  // Directly update message
      }));
    } else if (e?.target) {
      // Standard input fields
      const { name, value } = e.target;
      setForm((prevForm) => ({
        ...prevForm,
        [name]: value,
      }));
    }
  };

  const handleImage = async (e) => {
    const { name, files } = e.target;
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
  
    if (files && files.length > 0) {
      const file = files[0];
  
      if (!allowedTypes.includes(file.type)) {
        showMessage("Only images (PNG, JPEG) are allowed.", "error");
        return;
      }
  
      // Set the file name to state
      setImageName(file.name);
  
      try {
        const result = await FileUpload(files);
        setForm({ ...form, [name]: result.files?.[0]?.url });
      } catch (error) {
        showMessage("File upload failed", "error");
      }
    }
  };
  

  const validateForm = () => {
    var isValid = true
    const newErrors = {}

    // Validation logic for each input
    // if (!form.sd_role_id.trim()) {
    //   newErrors.sd_role_id = "Select is required"
    //   isValid = false
    // }

    setErrors(newErrors)
    // console.log(errors);
    // console.log(isValid);
    return isValid
  }

  const handleFormSubmit = async () => {
    validateForm()
    if (!!form.sd_role_id) {
      try {
        setApiStatus({ ...apiStatus, inProgress: true })
        const url = id
          ? `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/update_email_temp`
          : `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/add_email_temp`

        const data = {
          user_id: userId,
          sd_id: id || 0,
          sd_tem_name: temp,
          sd_role_id: form.sd_role_id,
          sd_cc_email: form.sd_cc_email,
          sd_from_email: form.sd_from_email,
          sd_subject: form.sd_subject,
          sd_message: form.sd_message,
          
          sd_image: form.sd_image,
          sd_button_name: form.sd_button_name,
          sd_button: form.sd_button,
          sd_flog: 0,
        }

        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }

        const response = await fetch(url, options, {})

        const addOrUpdateDocumentVault = await response.json()

        if (addOrUpdateDocumentVault?.success === true) {
          showMessage(addOrUpdateDocumentVault.message, "success")
          setTimeout(() => {
            type == 1 ? history("/email_list") : history("/email_list")
          }, 3000)
        } else {
          showMessage(addOrUpdateDocumentVault?.message, "error", false)
          setApiStatus({ ...apiStatus, inProgress: false })
        }
      } catch (e) {
        showMessage(e, "error", false)
        setApiStatus({ ...apiStatus, inProgress: false })
      }
    }
  }

  return (
    <React.Fragment>
      <AlertMessage />
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <h4 className="font-size-18">ADD Email</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/email_list">Scheduler</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/email_list">Email</Link>
              </li>
            </ol>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3 position-relative">
                    <label htmlFor="sd_tem_name" className="form-label">
                      Template Name 
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="sd_tem_name"
                      placeholder={temp}
                      disabled
                      onChange={handleForm}
                      value={temp}
                    />
                    
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="mb-3 position-relative">
                    <label htmlFor="sd_role_id" className="form-label">
                      Email To <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-control"
                      name="sd_role_id"
                      required
                      onChange={handleForm}
                      value={form.sd_role_id}
                    >
                      <option value="">Select Role</option>
                      <option value="0">Customer</option>
                      <option value="1">Admin</option>
                      <option value="2">Operator</option>
                      <option value="3">Business Partner</option>
                      <option value="4">Employee</option>
                    </select>
                    {errors.sd_role_id && (
                      <div className="invalid-input">{errors.sd_role_id}</div>
                    )}
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="mb-3 position-relative">
                    <label htmlFor="sd_cc_email" className="form-label">
                      Email CC
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="sd_cc_email"
                      placeholder="Email CC"
                      onChange={handleForm}
                      value={form.sd_cc_email}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="mb-3 position-relative">
                    <label htmlFor="sd_from_email" className="form-label">
                      Email From
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="sd_from_email"
                      placeholder="Email From"
                      onChange={handleForm}
                      value={form.sd_from_email}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-3 position-relative">
                    <label htmlFor="sd_subject" className="form-label">
                      Subject <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="sd_subject"
                      placeholder="Subject"
                      required
                      onChange={handleForm}
                      value={form.sd_subject}
                    />
                    {errors.sd_subject && (
                      <div className="invalid-input">{errors.sd_subject}</div>
                    )}
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="mb-3 position-relative">
                    <label htmlFor="sd_message" className="form-label">
                      Body
                    </label>
                    {/* <textarea
                      id="validationTooltip01"
                      name="sd_message"
                      rows="3"
                      className="form-control"
                      onChange={handleForm}
                      value={form.sd_message}
                    ></textarea> */}
                     <ReactQuill
                        id="sd_message"
                        name="sd_message"
                        value={form.sd_message}
                        onChange={content =>
                          handleForm(
                            {
                              target: {
                                name: "sd_message",
                                value: content,
                              },
                            },                            
                          )
                        }
                      />
                  </div>
                </div>

              

                <div className="col-md-4">
                  <div className="mb-3 position-relative">
                    <label
                      htmlFor="validationTooltipUsername"
                      className="form-label"
                    >
                      Image
                    </label>
                    <div className="input-group">
                      <input
                        type="file"
                        name="sd_image"
                        accept="image/*"
                        className="upload"
                        id="sd_image"
                        style={{ display: "none" }}
                        onChange={handleImage}
                      />
                      <label htmlFor="sd_image">
                        <p className="btn btn-info">Upload Image </p>
                      </label>
                    </div>
                  </div>
                  {imageName && <p className="mt-2 text-success">{imageName}</p>}
                </div>

                <div className="col-md-4">
                  <div className="mb-3 position-relative">
                    <label htmlFor="sd_button_name" className="form-label">
                      Button Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="sd_button_name"
                      placeholder="Button Name"
                      onChange={handleForm}
                      value={form.sd_button_name}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3 position-relative">
                    <label htmlFor="sd_button" className="form-label">
                      Button Link
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="sd_button"
                      placeholder="Button Link"
                      onChange={handleForm}
                      value={form.sd_button}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  {apiStatus.inProgress ? (
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light me-1"
                      disabled={true}
                    >
                      <Spinner color="light" size="sm">
                        Loading ...
                      </Spinner>
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light me-1"
                      onClick={handleFormSubmit}
                    >
                      {id ? "Save" : "Submit"}
                    </button>
                  )}
                  &nbsp; &nbsp;
                  <Link
                    to={type === 1 ? "/email_list" : "/email_list"}
                    className="btn btn-secondary waves-effect"
                  >
                    Cancel
                  </Link>
                  &nbsp; &nbsp;
                  {id != null && (
                    // <button
                    //   to={`#`}
                    //   onClick={e => handlePreviewClick(e, id)}
                    //   className="btn btn-outline-primary btn-sm"
                    //   title="Preview"
                    // >
                    //   Preview
                    // </button>
                    <button
                      onClick={e => handlePreviewClick(e, id)}
                      className="btn btn-outline-primary btn-sm"
                      title="Preview"
                    >
                      Preview
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
        {showModal && (
          <EmailTemplate
            showModal={showModal}
            handleClose={handleClose}
            id={selectedId}
          />
        )}
      </div>
    </React.Fragment>
  )
}

export default AddEmail
