import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"
import DocumentSearchTable from "components/DataTables/DocumentSearchTable"
import React, { useEffect, useState } from "react"
import { Form, Row, Col, Card, CardBody, Container, Button } from "reactstrap"

const DocumentSearchList = () => {
  useEffect(() => {
    localStorage.removeItem("tab");
    localStorage.removeItem("assetId");
    fetchAllDocuments(); // Load initial 20 records
  }, []);

  const initialFormDetails = {
    RegionList: [],
    documentName: "",
    country: "",
    region: "",
  };

  const [form, setForm] = useState(initialFormDetails);
  const [searchResults, setSearchResults] = useState(null); // null = default to first 20 records
  const [allDocuments, setAllDocuments] = useState([]);

  async function fetchAllDocuments() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/get_documents`
      );
      const getAllDocData = await response.json();
      console.log("Fetched Documents:", getAllDocData); // Debugging

      if (Array.isArray(getAllDocData.data)) {
        setAllDocuments(getAllDocData.data.slice(0, 20)); // Show first 20 records initially
      } else {
        console.error("Invalid data format:", getAllDocData);
      }
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  }

  async function fetchRegionsList(countryName) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/globals/regions?country=${countryName}`
      );
      const data = await response.json();

      setForm((prevForm) => ({
        ...prevForm,
        RegionList: data?.data || [],
        region: "",
        country: countryName,
      }));
    } catch (error) {
      console.error("Error fetching regions:", error);
    }
  }

  const handleForm = (e, action) => {
    const { name, value } = e.target;
    if (action === "documentsearch") {
      if (name === "country") {
        fetchRegionsList(value);
      }
      setForm((prevForm) => ({ ...prevForm, [name]: value }));
    }
  };

  const handleSearch = async () => {

    if (!form.documentName.trim()) {
      alert("Please enter a document name to search.");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/get_documents`
      );
      const getAllDocData = await response.json();
      console.log("Search Response:", getAllDocData); // Debugging
  
      if (!Array.isArray(getAllDocData.data)) {
        console.error("Invalid document data format");
        return;
      }
  
      const documentNameLower = form.documentName?.toLowerCase() || "";
  
      const filteredData = getAllDocData.data.filter((doc) => {
        const searchFields = ["dl_docName", "dl_docType", "dl_docSubtype", "dl_tags"];
        
        // Convert all fields to lowercase for case-insensitive comparison
        const matchesSearch = searchFields.some((field) =>
          doc[field]?.toLowerCase().includes(documentNameLower)
        );
  
        return (
          matchesSearch &&
          doc.dl_active === "Active" &&
          (!form.country || doc.dl_country?.toLowerCase() === form.country.toLowerCase()) &&
          (!form.region || doc.dl_region?.toLowerCase() === form.region.toLowerCase())
        );
      });
  
      console.log("Filtered Data:", filteredData); // Debugging
  
      setSearchResults(filteredData);
    } catch (error) {
      console.error("Error fetching or filtering documents:", error);
    }
  };
  

  // Show first 20 records only before searching
  const displayedData = searchResults !== null ? searchResults : allDocuments;

  console.log("Displayed Data:", displayedData); // Debugging

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <h4 className="font-size-18">DOCUMENT SEARCH LIST</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="/">Services</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/">Document Search List</a>
              </li>
            </ol>
          </div>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="document_name" className="form-label">
                          Document Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="documentName"
                          value={form.documentName}
                          onChange={(e) => handleForm(e, "documentsearch")}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="country" className="form-label">
                          Country
                        </label>
                        <select
                          className="form-select"
                          name="country"
                          value={form.country}
                          onChange={(e) => handleForm(e, "documentsearch")}
                        >
                          <option value="">Select Country</option>
                          <option value="USA">USA</option>
                          <option value="INDIA">INDIA</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="region" className="form-label">
                          Region
                        </label>
                        <select
                          className="form-select"
                          name="region"
                          value={form.region}
                          onChange={(e) => handleForm(e, "documentsearch")}
                        >
                          <option value="">Select Region</option>
                          {form.RegionList.map((each) => (
                            <option value={each.region} key={each.region}>
                              {each.region}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3 d-flex align-items-end">
                      <Button color="primary" onClick={handleSearch}>
                        Search
                      </Button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <div className="card">
            <DocumentSearchTable data={displayedData} />
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};




export default DocumentSearchList
