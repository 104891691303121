
import React, { useEffect, useState } from "react"
const { MDBDataTable } = require("mdbreact")
const { Row, Col, Card, CardBody, CardTitle } = require("reactstrap")
import "../../styles/datatables.scss"
import { Link } from "react-router-dom"
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"
import { loginUserData, userId } from "helpers/userId"

const SprintUsersListTables = () => {
  const [sprint, setSprintTool] = useState({ data: [] })

  useEffect(() => {
    const fetchData = async () => {
      const user_id =
        JSON.parse(localStorage.getItem("userData")).user.user_id || 0
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/get_sprint_tool_users_list`,
      )
      const data = await response.json()
      setSprintTool({ ...sprint, data: data?.data || [] })
    }
    fetchData()
  }, [])

  // const handleRemoveRole = async id => {
  //   try {
  //     const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/delete_documents`
  //     const userData = JSON.parse(localStorage.getItem("userData"))
  //     const data = {
  //       st_id: id,
  //     }
  //     const options = {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(data),
  //     }

  //     const response = await fetch(url, options, {})
  //     const removeData = await response.json()

  //     if (removeData?.success === true) {
  //       setDocumentVault({
  //         ...documents,
  //         data: documents?.data?.filter(each => each.st_id !== id),
  //       })

  //       showMessage(removeData.message, "success")
  //     } else {
  //       showMessage(removeData.message, "error", false)
  //     }
  //   } catch (error) {
  //     showMessage(removeData.message, "error", false)
  //   }
  // }

  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "Edit",
        field: "edit",
        sort: "asc",
        width: 150,
      },
      {
        label: "Sprint Name",
        field: "SprintUserName",
        sort: "asc",
        width: 270,
      },         
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: sprint?.data?.map((each, index) => ({
      sno: <div>{index + 1}</div>,
      edit: (
        <Link
          to={`/add_sprint_users?id=${each.stu_id}`}
          className="btn btn-outline-primary btn-sm edit"
          title="Edit"
        >
          <i className="fas fa-pencil-alt"></i>
        </Link>
      ),
      SprintUserName: each.stu_username || "",      
      action: (
        <div style={{ display: "flex", gap: "15px" }}>
          <i
            className="ion ion-md-trash"
            title="Delete"
            type="button"
            // onClick={() =>
            //   each.st_id &&
            //   window.confirm(`Are you sure you want to delete ${each.dl_docName}?`) &&
            //   handleRemoveRole(each.st_id)
            // }
          ></i>         
        </div>
      ),
    })),
  }

  return (
    <React.Fragment>
      <AlertMessage />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="h4">Sprint User List</CardTitle>
              <div className="col-12 d-flex">
                <div>
                  <Link
                    to="/add_sprint_users"
                    className="btn btn-danger my-2"
                    type="button"
                  >
                    Add Sprint Users
                  </Link>
                  &nbsp; &nbsp;
                  <Link
                    to="/tile_list"
                    className="btn btn-danger my-2"
                    type="button"
                  >
                    Back to Tile List
                  </Link>
                </div>
              </div>
              <MDBDataTable
                responsive
                bordered
                data={data}
                noBottomColumns={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default SprintUsersListTables
