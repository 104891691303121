import React, { useEffect, useState } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  Container,
  Form,
  Spinner,
} from "reactstrap"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"

const AddSprintUsers = props => {
  const history = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get("id")
  const [apiStatus, setApiStatus] = useState({
    inProgress: false,    
  })

  const [inititalState, setInitialState] = useState({
    stu_uid: "",
    stu_username: "",
     
  })

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: inititalState,
    validationSchema: Yup.object({
        stu_uid: Yup.string().required("Please Enter Popup/Message No."),
        stu_username: Yup.string().required("Please Enter Popup Text"),
             
    }),
    onSubmit: async values => {
      setApiStatus({ ...apiStatus, inProgress: true })
      const url = id
        ? `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/updatesprintuser`
        : `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/addsprintuser`

      const data = {
        stu_id: id || 0,
        stu_uid: values.stu_uid,
        stu_username: values.stu_username,              
      }

      try {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
        const response = await fetch(url, options, {})

        const addRegion = await response.json()

        if (addRegion?.success === true) {
          showMessage(addRegion.message, "success")
          setTimeout(() => {
            history("/sprint_users_list")
          }, 3000)
        } else {
          showMessage("Adding Region failed", "error", false)
          setApiStatus({ ...apiStatus, inProgress: false })
        }
      } catch (err) {
        showMessage(err, "error", false)
        setApiStatus({ ...apiStatus, inProgress: false })
      }
    },
  })

  useEffect(() => {
    if (parseInt(id)) {
      const fetchData = async () => {
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/sprint_users_list?stu_id=${
            id || 0
          }`
        )        

        const data = await response.json()
        const Doctypeinfo = data?.data[0] || ""
        if (Doctypeinfo) {
          let reInitialValues = { ...inititalState }
          reInitialValues.stu_id = Doctypeinfo.stu_id || ""
          reInitialValues.stu_uid = Doctypeinfo.stu_uid || ""
          reInitialValues.stu_username = Doctypeinfo.stu_username || ""                   
          
          setInitialState(reInitialValues)
        }
        // setRoleCollections({ ..., data: data?.data || [] })
      }
      fetchData()
    }
  }, [id])

 

  document.title = "Add Region | SOF"
  return (
    <React.Fragment>
      <div className="page-content">
        <AlertMessage />
        <Container fluid={true}>
          <Row>
            <Col xs="12" className="m-6">
          <div className="page-title-box">
            <h4 className="font-size-18"> {id ? "EDIT Sprint Users" : "ADD Sprint Users"} </h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="/">Operation</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/"> {id ? "Edit Sprint Users" : "Add Sprint Users"} </a>
              </li>
            </ol>
          </div>
              
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div className="p-3">
                    <Form
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                      action="#"
                    >
                      

                       

                      <Row>
                      <Col xl="4">
                          <div className="mb-3 register-passwordMain">
                            <Label className="form-label" htmlFor="stu_uid">
                              User List
                            </Label>
                            <Input
                              name="stu_uid"
                              className="form-control"
                              type="text"
                              id="stu_uid"
                              placeholder="Enter User Id"
                              onChange={validation.handleChange}
                              value={validation.values.stu_uid}
                              required
                            >                              
                            </Input>
                          </div>
                        </Col>
                        <Col xl="4">
                          <div className="mb-3 register-passwordMain">
                            <Label className="form-label" htmlFor="stu_username">
                            User Name
                            </Label>
                            <Input
                              name="stu_username"
                              className="form-control"
                              placeholder="Enter User Name"
                              type="text"
                              id="stu_username"
                              required
                              onChange={validation.handleChange}                              
                              value={validation.values.stu_username || ""}
                            />
                          </div>
                        </Col>                      
                      </Row>
                     

                      <div className="d-flex flex-row justify-content-start">
                        <div>
                          {apiStatus.inProgress ? (
                            <button className="btn btn-primary" type="button">
                              <Spinner color="light" size="sm">
                                Loading ...
                              </Spinner>
                            </button>
                          ) : (
                            <button className="btn btn-primary" type="submit">
                              {id ? "Save" : "Submit"} 
                            </button>
                          )}
                        </div>
                        <div>
                          <Link
                            to="/sprint_users_list"
                            className="btn btn-danger mx-2"
                            type="button"
                          >
                            Cancel
                          </Link>
                        </div>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddSprintUsers
