import { userId } from "helpers/userId";
import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import FamilyNode from "./FamilyNode";
import { Tree, TreeNode } from "react-organizational-chart";

const initialFormDetails = {
  user_name: "",
  user_fname: "",
  user_mname: "",
  user_lname: "",
  gender: "",
  profile_pic: "",
  relation: "",
};

const FamilyTree = () => {
  const [familyInformation, setFamilyInformation] = useState({ data: [] });
  const [nodes, setNodes] = useState([]);
  const [form, setForm] = useState(initialFormDetails);
  const [rootId, setRootId] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSelfData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/get_personal_info?user_id=${userId}`
        );
        if (!response.ok) throw new Error(`Failed to fetch personal info. Status: ${response.status}`);

        const data = await response.json();
        if (data?.success) {
          const personalInfo = data.data;
          const decryptedUserName = `${personalInfo.user_fname || ""} ${personalInfo.user_lname || ""}`.trim();

          setForm((prevForm) => ({
            ...prevForm,
            user_id: personalInfo.user_id,
            user_name: decryptedUserName,
            gender: personalInfo.gender,
            profile_pic: personalInfo.profile_pic,
            relation: "Self",
          }));

          setRootId(personalInfo.user_id.toString());
        }
      } catch (error) {
        setError("Error fetching personal information.");
        console.error("Error fetching personal information:", error);
      }
    };

    const fetchFamilyData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/family_information?user_id=${userId}`
        );
        if (!response.ok) throw new Error(`Failed to fetch family information. Status: ${response.status}`);

        const data = await response.json();
        setFamilyInformation({ data: data?.data || [] });
      } catch (error) {
        setError("Error fetching family information.");
        console.error("Error fetching family information:", error);
      }
    };

    fetchSelfData();
    fetchFamilyData();
  }, []);

  useEffect(() => {
    if (userId && form.user_id) {
      mapFamilyTree();
    }
  }, [form, familyInformation]);

  const mapFamilyTree = () => {
    const nodeMap = {};

    if (form.user_id === userId) {
      nodeMap[form.user_id] = {
        id: form.user_id.toString(),
        displayName: form.user_name,
        gender: form.gender,
        profilePic: form.profile_pic || "",
        associated: "yes",
        relation: "Self",
      
        children: [],
        spouse: null,
      };
    }

    familyInformation.data.forEach((person) => {
      if (["Spouse", "Son", "Daughter"].includes(person.ac_reliation)) {
        nodeMap[person.ac_id] = {
          id: person.ac_id.toString(),
          displayName: `${person.ac_fname} ${person.ac_lname}`,
          gender: person.ac_reliation === "Son" || person.ac_reliation === "Spouse" ? "male" : "female",
          profilePic: person.ac_image || "",
          relation: person.ac_reliation,
           associated: person.Associated,
          children: [],
        };
      }
    });

    const selfNode = nodeMap[form.user_id];
    if (selfNode) {
      // Link spouse to the self-node
      const spouseData = familyInformation.data.find((p) => p.ac_reliation === "Spouse");
      if (spouseData) {
        selfNode.spouse = nodeMap[spouseData.ac_id];
      }

      const childrenData = familyInformation.data.filter((p) => ["Son", "Daughter"].includes(p.ac_reliation));
      childrenData.forEach((child) => {
        const childNode = nodeMap[child.ac_id];
        if (childNode) {
          selfNode.children.push(childNode);
        }
      });
    }

    setNodes(Object.values(nodeMap));
  };

  const renderTree = (node) => (
    <TreeNode
      label={
        <div>
          <FamilyNode key={node.id} node={node} />
        </div>
      }
    >
      {node.children.map((child) => renderTree(child))}
    </TreeNode>
  );

  const rootNode = nodes.find((node) => node.relation === "Self");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {error && <div className="alert alert-danger">{error}</div>}
          <div className="page-title-box">
            <h4 className="font-size-18">FAMILY TREE</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="/">Account Info</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/">Family Tree</a>
              </li>
            </ol>
          </div>

          <div className="card">
            <div className="card-body">
              <h4 className="font-size-18">Family Tree</h4>
              <a className="btn btn-danger my-2" href="extended-family">
                Extended Family Tree
              </a>

              {nodes.length === 0 && (
                <div className="alert alert-info">No family data available.</div>
              )}

              {rootNode ? (
                <div className="d-flex justify-content-center">
                  <Tree
                    lineWidth={"2px"}
                    lineColor={"#ccc"}
                    lineBorderRadius={"10px"}
                    nodeRadius={"10px"}
                    label={
                      <div className="d-flex justify-content-center align-items-center">
                        <FamilyNode key={rootNode.id} node={rootNode} />
                        {rootNode.spouse && (
                          <>
                            <span style={{ margin: "10px 10px", color: "gray" }}>_______</span>
                            <FamilyNode key={rootNode.spouse.id} node={rootNode.spouse} />
                          </>
                        )}
                      </div>
                    }
                  >
                    {/*condition for the node */}
                    {rootNode.children.length > 0 && (
                      <TreeNode>
                        {(() => {
                          if (rootNode.children.length === 1) {
                            return (
                              // single child
                              <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                {rootNode.children.map((child) => (
                                  <div key={child.id} className="text-center">
                                    {renderTree(child)}
                                  </div>
                                ))}
                              </div>
                            );
                          } else {
                            return (
                              // If there are multiple children
                              <TreeNode style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "40px" }}>
                              {rootNode.children.map((child) => renderTree(child))}
                            </TreeNode>
                            );
                          }
                        })()}
                      </TreeNode>

                    )}
                  </Tree>
                </div>
              ) : (
                <div className="alert alert-info">No family data available.</div>
              )}
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );

};

export default FamilyTree;
