import AlertMessage, {
    showMessage,
  } from "components/alertmessages/Alertmessages"
  
  import GetSofBusinessTable from "components/Customers/Operations/GetSofBusinessTable"
  import { loginUserData, userId } from "helpers/userId"
  import React, { useEffect, useState } from "react"
  import { useNavigate, useLocation } from "react-router-dom"
  import { Container } from "reactstrap"
  
  
  const initialFormDetails = {
    
    regionsList: [],

    type: 0,
    searchFilter: "",
    userData: [],
    ac_id: 0,
    userFullName: "",
    city: "",
    country: "",
    relationsList: [],
    relationship: "",
    usersListData: [],
    SrvType: ""
  }
  
  const GetBusinessSof = () => {
   
    const [apiStatus, setApiStatus] = useState({ inProgress: false })
    const history = useNavigate()
    // to get api params
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const id = searchParams.get("id");
    const serviceType = searchParams.get("serviceType");
    initialFormDetails.SrvType = serviceType;
    const [form, setForm] = useState(initialFormDetails)
    

  
    const handleForm = async (e) => {
      const { name, value } = e.target;
      
      setForm((prevForm) => ({
          ...prevForm,
          [name]: value,
          ...(name === "user_country" ? { region: "" } : {}), // Reset region when country changes
      }));
  
      if (name === "user_country") {
          await fetchRegionsList(value);
      }
  };
  
  async function fetchRegionsList(countryName) {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_DOMAIN_URL}api/v1/globals/regions?country=${countryName}`
        );

        const data = await response.json();

        setForm((prevForm) => ({
            ...prevForm,
            regionsList: data?.data || [],
            region: "", // Ensure the user selects a region manually
            user_country: countryName,
        }));

        return data?.data;
    } catch (error) {
        console.log(error);
    }
}

      
  
  
      const search = async () => {
        try {
            let serviceTypeQuery = `%${form.SrvType}%`; // Ensure it matches backend query
      
            const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get-business-from-sof?region=${form.region}&country=${form.user_country}&serviceType=${serviceTypeQuery}`;
      
            // console.log("Fetching:", url);
      
            const response = await fetch(url);
            const data = await response.json();
            // console.log("API Response:", data);
      
            setForm({ ...form, userData: data?.data });
            return data?.data;
        } catch (e) {
            console.error("Error fetching data:", e);
            showMessage(e, "error", false);
        }
      };
      
    
  
    const handleSelectedUser = async each => {
      try {
        if (each?.vil_id) {
          const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/add_business_list_from_sof`
  
          const data = {
            vil_id: each.vil_id,
            vsl_id: each.vsl_id,
            Reqid: id, 
            user_id: userId,           
          }
  
          const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
  
          const response = await fetch(url, options, {})
  
          const result = await response.json()
  
          if (result?.success) {
            showMessage(result?.message, "success")
            history(-1)
          } else {
            showMessage(result?.message, "error", false)
          }
        }
      } catch (e) {
        showMessage(e, "error", false)
      }
    }
  
  
  
    return (
      <React.Fragment>
        <div className="page-content">
          <AlertMessage />
          <Container fluid>
            <div className="page-title-box">
              <h4 className="font-size-18">Get SOF</h4>
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <a href="/">Services</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/">Get SOF</a>
                </li>
              </ol>
            </div>
  
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <button
                    type="button"
                    onClick={() => history(-1)}
                    className="btn btn-danger my-2"
                  >
                    Back to Request Service
                  </button>
                </div>
              </div>
            </div>
  
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <div className="h4 card-title">Search for a business partner</div>
                  <div className="row d-flex align-items-center">
                    <div className="col-md-3">
                      <div className="mb-3 position-relative">
                        <label htmlFor="type" className="form-label">
                          Country
                        </label>
                        <select
                            className="form-select"
                            name="user_country"
                            id="country"
                            value={form.user_country}
                            onChange={handleForm}
                          >
                            <option value="" defaultValue>Select Country</option>
                            <option value="USA">
                              USA
                            </option>
                            <option value="INDIA">INDIA</option>
                          </select>
                      </div>
                    </div>
                  <div className="col-md-3">
                      <div className="mb-3 position-relative">
                          <label htmlFor="username" className="form-label">
                              State
                          </label>
                          <select
                              className="form-select"
                              name="region"
                              id="region"
                              value={form.region}
                              onChange={handleForm}
                          >
                              <option value="">Please select Region</option>
                              {form.regionsList.map((each) => (
                                  <option value={each.region} key={each.region}>
                                      {each.region}
                                  </option>
                              ))}
                          </select>
                      </div>
                  </div>

                    <div className="col-md-3">
                      <div className="mb-3 position-relative">
                        <label htmlFor="username" className="form-label">
                        Service Type
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            name="SrvType"
                            id="SrvType"
                            placeholder=""
                            required
                            onChange={handleForm}
                            value={form.SrvType}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 d-flex align-items-center">
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light mt-3"
                        onClick={search}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
           
              <div className="row">
                <div className="col-xl-12">
                  <div className="card">
                    <div className="h4 card-title m-4">List of Business</div>
                    <GetSofBusinessTable
                      userData={form.userData}
                      handleSelectedUser={handleSelectedUser}
                    />
                  </div>
                </div>
              </div>
           
          </Container>
        </div>
      </React.Fragment>
    )
  }
  
  export default GetBusinessSof
  