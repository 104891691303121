import React, { useEffect, useState } from "react"

const { MDBDataTable } = require("mdbreact")
const { Row, Col, Card, CardBody, CardTitle } = require("reactstrap")
import "../../styles/datatables.scss"
import { Link } from "react-router-dom"
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"


import "react-image-lightbox/style.css"; // Import Lightbox CSS
import Lightbox from "react-image-lightbox";

const RealtorServicesImagesTable = () => {
  const [assetImage, setAssetImage] = useState({ data: [] })
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get("id")
  //const assetId = parseInt(JSON.parse(localStorage.getItem("assetId")))

  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const user_id =
        JSON.parse(localStorage.getItem("userData")).user.user_id || 0
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/get_realtorservice_images_list?rsl_id=${id}`
      )
      const data = await response.json()
      setAssetImage({ ...assetImage, data: data?.data || [] })
    }
    fetchData()
  }, [])

  const handleRemoveRole = async id => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/delete_realtorservice_image`
      const userData = JSON.parse(localStorage.getItem("userData"))
      const data = {
        user_id: userData?.user?.user_id,
        rsp_id: id,
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options, {})
      const removeData = await response.json()

      if (removeData?.success === true) {
        setAssetImage({
          ...assetImage,
          data: assetImage?.data?.filter(each => each.rsp_id !== id),
        })

        showMessage(removeData.message, "success")
      } else {
        showMessage(removeData.message, "error", false)
      }
    } catch (error) {
      showMessage(removeData.message, "error", false)
    }
  }

  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "Image Name",
        field: "rsp_file",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],  
  }
  const rows = assetImage?.data?.map((each, index) => ({
    sno: <div>{index + 1} </div>,
    rsp_file: (
      <div>
        <img
          src={each.rsp_file}
          width="100"
          height="100"
          alt="Profile"
          onClick={() => {
            setPhotoIndex(index);
            setIsOpen(true);
          }}
          style={{ cursor: "pointer" }}
        />
      </div>
    ),
    action: (
      <div>
        <i
          className="ion ion-md-trash"
          title="Delete"
          type="button"
          onClick={() =>
            each.rsp_id &&
            window.confirm(
              `Are you sure do you want to delete ${each.rsp_file}?`
            ) && handleRemoveRole(each.rsp_id)
          }
        ></i>
      </div>
    ),
  }));

  return (
    <React.Fragment>
      <AlertMessage />

      <div>
      {/* Grid container */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
          gap: "20px",
        }}
      >
        {/* Iterate through the rows and display them in grid format */}
        {rows &&
          rows.map((row, index) => (
            <div
              key={index}
              style={{
                border: "1px solid #ccc",
                padding: "10px",
                textAlign: "center",
              }}
            >
              {row.sno}
              {row.rsp_file}
              {row.action}
            </div>
          ))}
      </div>

      {/* Lightbox component */}
      {isOpen && (
        <Lightbox
          mainSrc={assetImage.data[photoIndex].rsp_file}
          nextSrc={
            assetImage.data[(photoIndex + 1) % assetImage.data.length].rsp_file
          }
          prevSrc={
            assetImage.data[
              (photoIndex + assetImage.data.length - 1) %
                assetImage.data.length
            ].rsp_file
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + assetImage.data.length - 1) %
                assetImage.data.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % assetImage.data.length)
          }
        />
      )}
    </div>      
    </React.Fragment>
  )
}

export default RealtorServicesImagesTable
