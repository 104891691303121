import AlertMessage, {
    showMessage,
  } from "components/alertmessages/Alertmessages"
  import { decryptData } from "pages/Utility/enrypt_decrypt/crypto.secure"
  import React, { useEffect, useState } from "react"
  import { Link, useLocation, useNavigate } from "react-router-dom"
  import {
    Container,
    Spinner,
    Button,
    Modal,
    ModalBody,
    ModalHeader,
  } from "reactstrap"
  import "../../../../src//styles/errorfield.scss"
  import "../../../../src//styles/realtercss.scss"
  
  import { FileUpload } from "helpers/file_uploads/upload_handler"
  import { loginUserData, userId } from "helpers/userId"
  import RealtorServicesImagesTable from "components/DataTables/RealtorServicesImagesTable"

  import { useHistory } from "react-router-dom"
  const initialFormDetails = {
    property_name: "",
    customer_name: "",
    status: "",
    video: "",
        
  }
  
  const AddRealtorServices = () => {
    const [form, setForm] = useState({
        property_number: "",
        property_name: "",
        address: "",
        customer_name: "",
        realtor_service_by: "",
        amount: "",
        sof_commission: "",
        realtor_commission: "",
        main_realtor: "",
        contact_number: "",
        more_realtors: "",
        status: "",
        beds: "",
        baths: "",
        square_feet: "",
        property_type: "",
        built_in: "",
        estimate: "",
        hoa: "",
        
        whats_special: "",
        views: "",
        number_of_days: "",
        rent_amount: "",
        market_value: "",
        
        bedrooms_and_bathrooms: "",
        furniture: "",
        features: "",
        parking_area: "",
        construction_type: "",
        community_features: "",
        land_mark: "",
        appliances: "",
        video: null,
        imagesList: [],

        
    })
    const [apiStatus, setApiStatus] = useState({ inProgress: false })
    const [errors, setErrors] = useState({})
    const [changed, setChanged] = useState(undefined)

    // const allowedImageTypes = ["image/png", "image/jpeg", "image/jpg"];
    // const allowedVideoTypes = ["video/mp4", "video/mkv", "video/avi"];
  
     
    
    const [modal_xlarge, setmodal_xlarge] = useState(false)
    const [modal_small, setmodal_small] = useState(false)
    const [isUserValidated, setIsUserValidated] = useState(false)
    const [userKayVal, setUserKayVal] = useState("")
    const [displayImg, setDisplayImg] = useState({})
    const [docTypeCount, setDocTypeCount] = useState({})
    const removeBodyCss = () => {
      document.body.classList.add("no_padding")
    }
    const tog_small = () => {
      setmodal_small(!modal_small)
      removeBodyCss()
    }
  
    const tog_xlarge = () => {
      setmodal_xlarge(!modal_xlarge)
      removeBodyCss()
    }
    const handleUserKay = () => {
      if (userKayVal && userKayVal === loginUserData.user_key) {
        setIsUserValidated(false)
        tog_xlarge()
        tog_small()
      } else {
        setIsUserValidated(true)
      }
      setUserKayVal("")
    }
  
    const displayModel = (title, url) => {
      const fileType = /[^.]+$/.exec(url)
      const isImage = ["jpg", "jpeg", "png"].includes(fileType[0])
  
      let path =
        fileType[0] === "doc" || fileType[0] === "docx"
          ? `https://docs.google.com/gview?url=${url}&embedded=true`
          : url
  
      setDisplayImg({
        path: path,
        title: title,
        isImage: isImage,
        show: true,
        fileType: fileType,
      })
  
      setmodal_xlarge(true) // Directly open the document modal
    }
  
    // history for navigation
    const history = useNavigate()
  
    // to get api params
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const id = searchParams.get("id")
    const type = searchParams.get("type")


    
    const [videoName, setVideoName] = useState("");
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
  
    // -- Fetch Document Vault list start -- //
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/single_realtorservice?rsl_id=${id}`,
        )
  
        if (!response.ok) {
          throw new Error(`Failed to fetch data. Status: ${response.status}`)
        }
  
        const data = await response.json()
  
        if (data?.success) {
          const acData = data?.data[0]
                 
  
          setForm({
            ...form,            
            property_number: acData.property_number,
            property_name: acData.property_name,
            address: acData.address,
            customer_name: acData.customer_name,
            realtor_service_by: acData.realtor_service_by,
            amount: acData.amount,
            sof_commission: acData.sof_commission,
            realtor_commission: acData.realtor_commission,
            main_realtor: acData.main_realtor,
            contact_number: acData.contact_number,
            more_realtors: acData.more_realtors,
            status: acData.status,
            beds: acData.beds,
            baths: acData.baths,
            square_feet: acData.square_feet,
            property_type: acData.property_type,
            built_in: acData.built_in,
            estimate: acData.estimate,
            hoa: acData.hoa,
            
            whats_special: acData.whats_special,
            views: acData.views,
            number_of_days: acData.number_of_days,
            rent_amount: acData.rent_amount,
            market_value: acData.market_value,
            
            bedrooms_and_bathrooms: acData.bedrooms_and_bathrooms,
            furniture: acData.furniture,
            features: acData.features,
            parking_area: acData.parking_area,
            construction_type: acData.construction_type,
            community_features: acData.community_features,
            land_mark: acData.land_mark,
            appliances: acData.appliances,
            video: acData.video,
            
          })
        } else {
          throw new Error("Data fetching was not successful")
        }
      } catch (err) {
        history("/realtor_service_list")
      }
    }
  
    useEffect(() => {
      if (id) {
        fetchData()
      }
    }, [id])
  
   
  
    const handleForm = e => {
      const { name, value } = e.target
      setForm(prevForm => ({
        ...prevForm,
        [name]: value,
      }))
    }
  
  
    const FileUploadWithProgress = async (file, onProgress) => {
      return new Promise((resolve) => {
        const totalSize = file.size;
        let uploaded = 0;
    
        const interval = setInterval(() => {
          uploaded += totalSize * 0.05; // Upload 5% at a time
          const progress = Math.min((uploaded / totalSize) * 100, 100);
          onProgress(progress);
    
          if (progress === 100) {
            clearInterval(interval);
            setTimeout(() => {
              resolve({ files: [{ url: URL.createObjectURL(file) }] });
            }, 500);
          }
        }, 300);
      });
    };
    
    

    const handleUpload = async (e, type) => {
      const { files } = e.target;
      if (!files || files.length === 0) {
        showMessage("No file selected. Please select a file.", "error");
        return;
      }
  
      try {
        if (type === "images") {
          const allowedImageTypes = ["image/png", "image/jpeg", "image/jpg"];
          const selectedFiles = [...files];

           // Validate total images count
          if ((form.imagesList?.length || 0) + selectedFiles.length > 20) {
            showMessage("You can upload a maximum of 20 images.", "error");
            return;
          }
  
          // Validate images
          const invalidFiles = selectedFiles.filter((file) => !allowedImageTypes.includes(file.type));
          if (invalidFiles.length > 0) {
            showMessage("Only PNG, JPEG, JPG images are allowed.", "error");
            return;
          }
  
          // Upload images
          const result = await FileUpload(selectedFiles);
          if (!result || !result.files || result.files.length === 0) {
            showMessage("Image upload failed. Please try again.", "error");
            return;
          }
  
          // Store uploaded image URLs
          const uploadedImages = result.files.map((file) => ({ rsp_file: file.url }));
          setForm((prevForm) => ({
            ...prevForm,
            imagesList: [...prevForm.imagesList, ...uploadedImages],
          }));
  
          showMessage("Images uploaded successfully!", "success");
        } else if (type === "video") {
          const allowedVideoTypes = ["video/mp4", "video/mkv", "video/avi"];
          const file = files[0];
  
          if (!allowedVideoTypes.includes(file.type)) {
            showMessage("Only MP4, MKV, and AVI videos are allowed.", "error");
            return;
          }

           // Validate file size (3MB = 3 * 1024 * 1024 bytes)
            if (file.size > 3 * 1024 * 1024) {
              showMessage("File size must be less than 3MB.", "error");
              return;
            }
  
          setVideoName(file.name);
          setUploadProgress(0);
          setIsUploading(true);
  
          const result = await FileUploadWithProgress(file, setUploadProgress);
  
          if (!result || !result.files || result.files.length === 0) {
            showMessage("Video upload failed. Please try again.", "error");
            setIsUploading(false);
            return;
          }
  
          setForm((prevForm) => ({
            ...prevForm,
            video: result.files[0].url,
          }));
  
          setIsUploading(false);
          showMessage("Video uploaded successfully!", "success");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        showMessage("Failed to upload files. Please try again.", "error");
        setIsUploading(false);
      }
    };
    
    const handleDeleteImage = (index) => {
      setForm(prevForm => ({
        ...prevForm,
        imagesList: prevForm.imagesList.filter((_, i) => i !== index),
      }));
    }; 
    


  
    const validateForm = () => {
      var isValid = true
      const newErrors = {}
  
      setErrors(newErrors)
      return isValid
    }
  
    

    const handleFormSubmit = async () => {
      validateForm();
    
      if (!!form.property_number) {
        try {
          setApiStatus({ ...apiStatus, inProgress: true });
    
          const url = id
            ? `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/update_realtorservice`
            : `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/add_realtorservice`;
    
          const data = {
            rsl_id: id || 0,
            property_number: form.property_number,
            property_name: form.property_name,
            address: form.address,
            customer_name: form.customer_name,
            realtor_service_by: form.realtor_service_by,
            amount: form.amount,
            sof_commission: form.sof_commission,
            realtor_commission: form.realtor_commission,
            main_realtor: form.main_realtor,
            contact_number: form.contact_number,
            more_realtors: form.more_realtors,
            status: form.status,
            beds: form.beds,
            baths: form.baths,
            square_feet: form.square_feet,
            property_type: form.property_type,
            built_in: form.built_in,
            estimate: form.estimate,
            hoa: form.hoa,
            whats_special: form.whats_special,
            views: form.views,
            number_of_days: form.number_of_days,
            rent_amount: form.rent_amount,
            market_value: form.market_value,
            bedrooms_and_bathrooms: form.bedrooms_and_bathrooms,
            furniture: form.furniture,
            features: form.features,
            parking_area: form.parking_area,
            construction_type: form.construction_type,
            community_features: form.community_features,
            land_mark: form.land_mark,
            appliances: form.appliances,
            video: form.video,  // ✅ Single video file path
            realtor_services_photos: form.imagesList, // ✅ Multiple images paths
          };
    
          const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          };
    
          const response = await fetch(url, options);
          const result = await response.json();
    
          if (result.success) {
            showMessage(result.message, "success");
            setTimeout(() => {
              history("/realtor_service_list");
            }, 3000);
          } else {
            showMessage(result.message, "error", false);
            setApiStatus({ ...apiStatus, inProgress: false });
          }
        } catch (e) {
          showMessage(e.message, "error", false);
          setApiStatus({ ...apiStatus, inProgress: false });
        }
      }
    };
    
    
  
    return (
      <React.Fragment>
        <AlertMessage />
        <div className="page-content">
          <Container fluid>
            <div className="page-title-box">
              <h4 className="font-size-18">ADD Realtor Service</h4>
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/realtor_service_list">Operation</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/realtor_service_list">Realtor Service</Link>
                </li>
              </ol>
            </div>
            <div className="card">
              <div className="card-body">

              <h4 className="page-title-box">Property Details</h4>

                <div className="row">

                    <div className="col-md-4">
                    <div className="mb-3 position-relative">
                        <label htmlFor="property_number" className="form-label">
                        Property Number <span className="text-danger">*</span>
                        </label>
                        <input
                        type="text"
                        className="form-control"
                        name="property_number"
                        placeholder="Property Number"
                        onChange={handleForm}
                        value={form.property_number}
                        />
                        {errors.property_number && (
                      <div className="invalid-input">
                        {errors.property_number}
                      </div>
                    )}
                    </div>
                    </div>
                    
                    <div className="col-md-4">
                    <div className="mb-3 position-relative">
                        <label htmlFor="property_name" className="form-label">
                        Property Name <span className="text-danger">*</span>
                        </label>
                        <input
                        type="text"
                        className="form-control"
                        name="property_name"
                        placeholder="Property Name"
                        onChange={handleForm}
                        value={form.property_name}
                        />
                         {errors.property_name && (
                      <div className="invalid-input">
                        {errors.property_name}
                      </div>
                    )}
                    </div>
                    </div>

                    <div className="col-md-4">
                    <div className="mb-3 position-relative">
                        <label htmlFor="address" className="form-label">
                        Address <span className="text-danger">*</span>
                        </label>
                        <textarea
                        className="form-control"
                        name="address"
                        placeholder="Address"
                        onChange={handleForm}
                        value={form.address}
                        />
                         {errors.address && (
                      <div className="invalid-input">
                        {errors.address}
                      </div>
                    )}
                    </div>
                    </div>

                    <div className="col-md-4">
                    <div className="mb-3 position-relative">
                        <label htmlFor="customer_name" className="form-label">
                        Customer Name <span className="text-danger">*</span>
                        </label>
                        <input
                        type="text"
                        className="form-control"
                        name="customer_name"
                        placeholder="Customer Name"
                        onChange={handleForm}
                        value={form.customer_name}
                        />
                        {errors.customer_name && (
                      <div className="invalid-input">
                        {errors.customer_name}
                      </div>
                    )}
                    </div>
                    </div>

                    <div className="col-md-4">
                    <div className="mb-3 position-relative">
                        <label htmlFor="realtor_service_by" className="form-label">
                        Realtor Service By
                        </label>
                        <input
                        type="text"
                        className="form-control"
                        name="realtor_service_by"
                        placeholder="Realtor Service By"
                        onChange={handleForm}
                        value={form.realtor_service_by}
                        />
                    </div>
                    </div>

                    <div className="col-md-4">
                    <div className="mb-3 position-relative">
                        <label htmlFor="amount" className="form-label">
                        Amount <span className="text-danger">*</span>
                        </label>
                        <input
                        type="number"
                        className="form-control"
                        name="amount"
                        placeholder="Amount"
                        onChange={handleForm}
                        value={form.amount}
                        />
                        {errors.amount && (
                      <div className="invalid-input">
                        {errors.amount}
                      </div>
                    )}
                    </div>
                    </div>

                    <div className="col-md-4">
                    <div className="mb-3 position-relative">
                        <label htmlFor="sof_commission" className="form-label">
                        SOF Commission
                        </label>
                        <input
                        type="number"
                        className="form-control"
                        name="sof_commission"
                        placeholder="SOF Commission"
                        onChange={handleForm}
                        value={form.sof_commission}
                        />
                    </div>
                    </div>

                    <div className="col-md-4">
                    <div className="mb-3 position-relative">
                        <label htmlFor="realtor_commission" className="form-label">
                        Realtor Commission
                        </label>
                        <input
                        type="number"
                        className="form-control"
                        name="realtor_commission"
                        placeholder="Realtor Commission"
                        onChange={handleForm}
                        value={form.realtor_commission}
                        />
                    </div>
                    </div>

                    <div className="col-md-4">
                    <div className="mb-3 position-relative">
                        <label htmlFor="main_realtor" className="form-label">
                        Main Realtor
                        </label>
                        <input
                        type="text"
                        className="form-control"
                        name="main_realtor"
                        placeholder="Main Realtor"
                        onChange={handleForm}
                        value={form.main_realtor}
                        />
                    </div>
                    </div>

                    <div className="col-md-4">
                    <div className="mb-3 position-relative">
                        <label htmlFor="contact_number" className="form-label">
                        Contact Number
                        </label>
                        <input
                        type="text"
                        className="form-control"
                        name="contact_number"
                        placeholder="Contact Number"
                        onChange={handleForm}
                        value={form.contact_number}
                        />
                    </div>
                    </div>

                    <div className="col-md-4">
                    <div className="mb-3 position-relative">
                        <label htmlFor="more_realtors" className="form-label">
                        More Realtors
                        </label>
                        <textarea
                        className="form-control"
                        name="more_realtors"
                        placeholder="More Realtors"
                        onChange={handleForm}
                        value={form.more_realtors}
                        />
                    </div>
                    </div>

                    <div className="col-md-4">
                        <div className="mb-3 position-relative">
                        <label htmlFor="status" className="form-label">
                            Status
                        </label>
                        <select
                        className="form-select"
                        name="status"
                        id="status"
                        value={form.status}
                        onChange={handleForm}
                        >
                        <option value="" defaultValue>
                            Select Status
                        </option>
                        <option value="Buy">Buy</option>
                        <option value="Sell">Sell</option>
                        <option value="Bought">Bought</option>
                        <option value="Sold">Sold</option>                        
                        </select>
                        
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="mb-3 position-relative">
                        <label htmlFor="beds" className="form-label">
                            Beds
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            name="beds"
                            placeholder="Beds"
                            onChange={handleForm}
                            value={form.beds}
                        />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="mb-3 position-relative">
                        <label htmlFor="baths" className="form-label">
                            Baths
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            name="baths"
                            placeholder="Baths"
                            onChange={handleForm}
                            value={form.baths}
                        />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="mb-3 position-relative">
                        <label htmlFor="square_feet" className="form-label">
                            Square Feet
                        </label>
                        <input
                            type="number"
                            step="0.01"
                            className="form-control"
                            name="square_feet"
                            placeholder="Square Feet"
                            onChange={handleForm}
                            value={form.square_feet}
                        />
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="mb-3 position-relative">
                        <label htmlFor="property_type" className="form-label">
                            Property Type
                        </label>
                        <select
                        className="form-select"
                        name="property_type"
                        id="property_type"
                        value={form.property_type}
                        onChange={handleForm}
                        >
                        <option value="" defaultValue>
                            Select Property Type
                        </option>
                        
                        <option value="Multi Family">Multi Family</option>
                        <option value="Single Family">Single Family</option>
                        <option value="Land">Land</option>
                        <option value="Commercial">Commercial</option>
                        </select>                       
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="mb-3 position-relative">
                        <label htmlFor="built_in" className="form-label">
                            Built In (Year)
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            name="built_in"
                            placeholder="Built In"
                            onChange={handleForm}
                            value={form.built_in}
                        />
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="mb-3 position-relative">
                        <label htmlFor="estimate" className="form-label">
                            Estimate
                        </label>
                        <input
                            type="number"
                            step="0.01"
                            className="form-control"
                            name="estimate"
                            placeholder="Estimate"
                            onChange={handleForm}
                            value={form.estimate}
                        />
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="mb-3 position-relative">
                        <label htmlFor="hoa" className="form-label">
                            HOA
                        </label>
                        <input
                            type="number"
                            step="0.01"
                            className="form-control"
                            name="hoa"
                            placeholder="HOA"
                            onChange={handleForm}
                            value={form.hoa}
                        />
                        </div>
                    </div>

                   

                   </div>
              </div>
            </div>

            <div className="card">
              <div className="card-body">
              <h4 className="page-title-box"> More Information</h4>
                <div className="row">
                  

                    

                    <div className="col-md-4">
                        <div className="mb-3 position-relative">
                        <label htmlFor="whats_special" className="form-label">
                            What's Special
                        </label>
                        <textarea
                            className="form-control"
                            name="whats_special"
                            placeholder="What's Special"
                            onChange={handleForm}
                            value={form.whats_special}
                        />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="mb-3 position-relative">
                        <label htmlFor="views" className="form-label">
                            Views
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            name="views"
                            placeholder="Views"
                            onChange={handleForm}
                            value={form.views}
                        />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="mb-3 position-relative">
                        <label htmlFor="number_of_days" className="form-label">
                            Number of Days
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            name="number_of_days"
                            placeholder="Number of Days"
                            onChange={handleForm}
                            value={form.number_of_days}
                        />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="mb-3 position-relative">
                        <label htmlFor="rent_amount" className="form-label">
                            Rent Amount
                        </label>
                        <input
                            type="number"
                            step="0.01"
                            className="form-control"
                            name="rent_amount"
                            placeholder="Rent Amount"
                            onChange={handleForm}
                            value={form.rent_amount}
                        />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="mb-3 position-relative">
                        <label htmlFor="market_value" className="form-label">
                            Market Value
                        </label>
                        <input
                            type="number"
                            step="0.01"
                            className="form-control"
                            name="market_value"
                            placeholder="Market Value"
                            onChange={handleForm}
                            value={form.market_value}
                        />
                        </div>
                    </div>
                    
                  
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-body">
              <h4 className="page-title-box"> Facts & Features</h4>
                <div className="row">                

                    <div className="col-md-4">
                        <div className="mb-3 position-relative">
                        <label htmlFor="bedrooms_and_bathrooms" className="form-label">
                            Bedrooms & Bathrooms
                        </label>
                        <textarea
                            className="form-control"
                            name="bedrooms_and_bathrooms"
                            placeholder="Bedrooms & Bathrooms"
                            onChange={handleForm}
                            value={form.bedrooms_and_bathrooms}
                        />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="mb-3 position-relative">
                        <label htmlFor="furniture" className="form-label">
                            Furniture
                        </label>
                        <textarea
                            className="form-control"
                            name="furniture"
                            placeholder="Furniture"
                            onChange={handleForm}
                            value={form.furniture}
                        />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="mb-3 position-relative">
                        <label htmlFor="features" className="form-label">
                            Features
                        </label>
                        <textarea
                            className="form-control"
                            name="features"
                            placeholder="Features"
                            onChange={handleForm}
                            value={form.features}
                        />
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="mb-3 position-relative">
                        <label htmlFor="parking_area" className="form-label">
                            Parking Area
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            name="parking_area"
                            placeholder="Parking Area"
                            onChange={handleForm}
                            value={form.parking_area}
                        />
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="mb-3 position-relative">
                        <label htmlFor="construction_type" className="form-label">
                            Construction Type
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            name="construction_type"
                            placeholder="Construction Type"
                            onChange={handleForm}
                            value={form.construction_type}
                        />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="mb-3 position-relative">
                        <label htmlFor="community_features" className="form-label">
                            Community Features
                        </label>
                        <textarea
                            className="form-control"
                            name="community_features"
                            placeholder="Community Features"
                            onChange={handleForm}
                            value={form.community_features}
                        />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="mb-3 position-relative">
                        <label htmlFor="land_mark" className="form-label">
                            Landmark
                        </label>
                        <textarea
                            className="form-control"
                            name="land_mark"
                            placeholder="Landmark"
                            onChange={handleForm}
                            value={form.land_mark}
                        />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="mb-3 position-relative">
                        <label htmlFor="appliances" className="form-label">
                            Appliances
                        </label>
                        <textarea
                            className="form-control"
                            name="appliances"
                            placeholder="Appliances"
                            onChange={handleForm}
                            value={form.appliances}
                        />
                        </div>
                    </div>

                  <div className="col-md-4">
                    <div className="mb-3 position-relative">
                      <label
                        htmlFor="validationTooltipUsername"
                        className="form-label"
                      >
                        Video
                      </label>
                      <div className="input-group">
                        <input
                          type="file"
                          name="video"
                          //accept="image/*,.pdf,.doc,.docx"
                          className="upload"
                          id="video"
                          style={{ display: "none" }}
                          accept="video/mp4, video/mkv, video/avi"
                          onChange={(e) => handleUpload(e, "video")}
                        />
                        <label htmlFor="video">
                          <p className="btn btn-info">Upload Video </p>
                        </label>
                      </div>
                    </div>

                  {/* Show Video Name */}
                  {videoName && <p><strong>Uploaded Video:</strong> {videoName}</p>}

                  {/* Show Upload Progress */}
                  {isUploading && (
                    <div className="progress mt-2">
                      <div
                        className="progress-bar progress-bar-striped progress-bar-animated"
                        role="progressbar"
                        style={{ width: `${uploadProgress}%` }}
                      >
                        {Math.round(uploadProgress)}%
                      </div>
                    </div>
                  )}

                  <br></br>
  
                {id ? (
                  <Button
                    color="primary"
                    onClick={() =>
                      displayModel(form.property_name, form.video)
                    }
                  >
                    View Video
                  </Button>
                ) : null}
  
                {/* Direct Modal to display document */}
                {displayImg.show && (
                  <Modal isOpen={modal_xlarge} toggle={tog_xlarge} size="xl">
                    <ModalHeader className="mt-0" toggle={tog_xlarge}>
                      {displayImg.title}
                    </ModalHeader>
                    <ModalBody>
                      {displayImg.isImage ? (
                        <img src={displayImg.path} width="100%"></img>
                      ) : (
                        <iFrame
                          src={displayImg.path}
                          width="100%"
                          height="650px"
                        ></iFrame>
                      )}
                    </ModalBody>
                  </Modal>
                )}
                  </div>
                  
                    
                  </div>
                </div>
            </div>


                      <div className="card">
                              <div className="card-body">
                                <h4 className="card-title">
                                  Upload Multiple Images
                                </h4>
                                <div className="row">
                                  <div className="col-md-4">
                                    <input
                                      type="file"
                                      name="rsp_file"
                                      accept="image/png, image/jpeg, image/jpg"
                                      className="upload"
                                      id="asset_images"
                                      multiple
                                      // style={{ display: "none" }}
                                      onChange={(e) => handleUpload(e, "images")}
                                    />
                                  </div>
                                </div>
                              

                            {/* Show uploaded images in a grid */}
                              {form.imagesList && form.imagesList.length > 0 ? (
                                <div className="image-grid">
                                  {form.imagesList.map((image, index) => (
                                    <div key={index} className="image-item">
                                      <img src={image.rsp_file} alt={`Uploaded ${index}`} width="100" />
                                      <button className="delete-btn" onClick={() => handleDeleteImage(index)}>
                                        ✖
                                      </button>
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                <p>No images uploaded.</p>
                              )}
                            </div>

                              <div className="card">
                                <RealtorServicesImagesTable
                                  form={form}
                                  handleForm={handleForm}
                                  rsl_id={id}
                                 // activeTab={activeTab}
                                />
                              </div>
                            </div>
            
            


  
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    {apiStatus.inProgress ? (
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light me-1"
                        disabled={true}
                      >
                        <Spinner color="light" size="sm">
                          Loading ...
                        </Spinner>
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light me-1"
                        onClick={handleFormSubmit}
                      >
                        {id ? "Save" : "Submit"}
                      </button>
                    )}
                    &nbsp; &nbsp;
                    <Link
                      to={type == 1 ? "/realtor_service_list" : "/realtor_service_list"}
                      className="btn btn-secondary waves-effect"
                    >
                      Cancel
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    )
  }
  
  export default AddRealtorServices
  