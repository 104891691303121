import React, { useEffect, useState } from "react"

const { MDBDataTable } = require("mdbreact")
import { Card, CardBody, Row, Col, Button } from "reactstrap";
import "../../styles/datatables.scss"
import { Link } from "react-router-dom"
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"
import { userId } from "helpers/userId"

const TileListTAble = () => {
    const [featureList, setFeatureList] = useState([]);
  
    useEffect(() => {
      const fetchData = async () => {
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/get_tile_list`
        );
        const data = await response.json();
        setFeatureList(data?.data || []);
      };
      fetchData();
    }, []);
  
    const handleRemoveFeature = async (id) => {
      if (!window.confirm("Are you sure you want to delete this Tile?")) {
        return;
      }
  
      try {
        const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/delete_tile_list`;
        const data = { SprintNo: id };
  
        const response = await fetch(url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        });
  
        const result = await response.json();
  
        if (result?.success) {
          setFeatureList(featureList.filter((feature) => feature.SprintNo !== id));
          alert(result.message);
        } else {
          alert(result.message);
        }
      } catch (error) {
        alert("Error deleting feature.");
      }
    };
  
    return (
      <Row className="mt-4">   
      <div className="col-12 d-flex button-toolbar">
                <div>
        <Link
         to="/sprint_users_list"
         className="btn btn-danger my-2"
         type="button"
     >
         Sprint User List
     </Link> 
     &nbsp; &nbsp;

     <Link
         to="/add-tile"
         className="btn btn-danger my-2"
         type="button"
     >
         Add Tile
     </Link>

     

       </div>
       </div>                    
        {featureList.map((feature, index) => (
          <Col md={4} sm={6} xs={12} key={feature.SprintNo} className="mb-4">
            <Card className="border shadow-sm">
              <CardBody>
                <h5 className="mb-2"><strong>Sprint No:</strong> &nbsp; &nbsp;&nbsp; &nbsp;  {feature.SprintNo}</h5>
                <p><strong>Sprint Name:</strong> &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;{feature.SprintName || "N/A"}</p>
                <p><strong>Create Date:</strong> &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{feature.CreateDate || "N/A"}</p>
                <p><strong>Features:</strong> &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; C - {feature.completedCount || "0"}  | NC - {feature.notCompletedCount || "0"}</p>
                <p><strong>Points:</strong> &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;{feature.totalPoints || 0 }</p>
                <div className="d-flex justify-content-between">
                  <Link to={`/sprint_list?id=${feature.SprintNo}`} className="btn btn-sm btn-primary">
                    Details
                  </Link>
                  <Button color="danger" size="sm" onClick={() => handleRemoveFeature(feature.SprintNo)}>
                    Delete
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    );
  };
  
  

export default TileListTAble
