// using family tree
// import { userId } from "helpers/userId";
// import { Link } from "react-router-dom";
// import React, { useEffect, useState, useCallback } from "react";
// import { Container } from "reactstrap";
// import ExtendedFamilyNode from "./ExtendedFamilyNode";
// import ReactFamilyTree from "react-family-tree";

// const initialFormDetails = {
//   user_name: "",
//   user_fname: "",
//   user_mname: "",
//   user_lname: "",
//   gender: "",
//   profile_pic: "",
//   relation: "",
// };

// const ExtendedFamily = () => {
//   const [familyInformation, setFamilyInformation] = useState({ data: [] });
//   const [nodes, setNodes] = useState([]);
//   const [form, setForm] = useState(initialFormDetails);
//   const [error, setError] = useState(null);

//   const WIDTH = 260;
//   const HEIGHT = 280;
//   const rootId = userId.toString();

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch(
//           `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/family_information?user_id=${userId}`,
//         );
//         if (!response.ok) {
//           throw new Error(
//               `Error fetching family information: ${response.statusText}`,
//           );
//         }
//         const data = await response.json();
//         setFamilyInformation({ data: data?.data || [] });
//       } catch (error) {
//         console.error("Error fetching family information:", error);
//         setError("Failed to load family information. Please try again later.");
//       }
//     };

//     const fetchSelfData = async () => {
//       try {
//         const response = await fetch(
//             `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/get_personal_info?user_id=${userId}`,
//         );
//         if (!response.ok) {
//           throw new Error(
//               `Error fetching personal information: ${response.statusText}`,
//           );
//         }
//         const data = await response.json();
//         if (data?.success) {
//           const personalInfo = data.data;
//           const decryptedUserName = `${personalInfo.user_fname || ""} ${personalInfo.user_lname || ""
//           }`.trim();

//           setForm({
//             ...form,
//             user_id: personalInfo.user_id.toString(),
//             user_key: personalInfo.user_key,
//             user_name: decryptedUserName,
//             user_fname: personalInfo.user_fname,
//             user_mname: personalInfo.user_mname,
//             user_lname: personalInfo.user_lname,
//             gender: personalInfo.gender,
//             profile_pic: personalInfo.profile_pic,
//             relation: "self",
//             associated: "yes",
//           });
//         } else {
//           throw new Error(
//               "Failed to load self-data. Check your profile information.",
//           );
//         }
//       } catch (error) {
//         console.error("Error fetching personal information:", error);
//         setError("Failed to load personal information. Please try again later.");
//       }
//     };

//     fetchData();
//     fetchSelfData();
//   }, []);

//   const mapFamilyTree = useCallback(() => {
//     const nodeMap = {};

//     if (form.user_id > 0) {
//       nodeMap[form.user_id] = {
//         id: form.user_id.toString(),
//         gender: form.gender,
//         profilePic: form.profile_pic || "",
//         displayName: form.user_name,
//         relation: "Self",
//         associated: form.associated,
//         parents: [],
//         siblings: [],
//         spouses: [],
//         children: [],
//         inLaws: [],
//       };
//     }

//     familyInformation.data.forEach((person) => {
//       const gender = [
//         "Mother", "Sister", "Daughter", "Mother-in-law", "Sister-in-law"
//       ].includes(person.ac_reliation)
//         ? "female"
//         : [
//             "Father", "Brother", "Son", "Spouse", "Father-in-law", "Brother-in-law"
//           ].includes(person.ac_reliation)
//           ? "male"
//           : "unknown";

//   nodeMap[person.ac_id] = {
//     id: person.ac_id.toString(),
//     gender: gender,
//     profilePic: person.ac_image || "",
//     displayName: `${person.ac_fname} ${person.ac_lname}`,
//     relation: person.ac_reliation,
//     associated: person.Associated,
//     parents: [],
//     siblings: [],
//     spouses: [],
//     children: [],
//     inLaws: [], //
//   };
// });

//     familyInformation.data.forEach((person) => {
//       const node = nodeMap[person.ac_id]; 
//       const mainNode = nodeMap[form.user_id];
//       if (!node || !mainNode) return; // Skip if nodes are not found

//       switch (person.ac_reliation) {
//         case "Spouse":
//           // Connect Self and Spouse
//           if (!mainNode.spouses.includes(node)) mainNode.spouses.push(node);
//           if (!node.spouses.includes(mainNode)) node.spouses.push(mainNode);
//           break;

//           case "Father":
//             case "Mother":
//               if (!mainNode.parents.includes(node)) mainNode.parents.push(node);
//               if (!node.children.includes(mainNode)) node.children.push(mainNode);

//               // Ensure siblings are always shown on the left of parents
//               if (mainNode.siblings.length > 0) {
//                 mainNode.siblings.forEach((sibling) => {
//                   if (!sibling.parents.includes(node)) sibling.parents.push(node);
//                   if (!node.children.includes(sibling)) node.children.push(sibling);
//                 });

//                 // Move siblings before parents in the array for correct left-side positioning
//                 mainNode.siblings = [...mainNode.siblings, ...mainNode.parents];
//                 mainNode.parents = [];
//               }
//               break;


//         case "Father-in-law":
//         case "Mother-in-law":

//           if (mainNode.spouses.length > 0) {
//             mainNode.spouse.forEach((spouse) => {
//               if (!spouse.inLaws.includes(node)) spouse.inLaws.push(node);
//               if (!node.spouses.includes(spouse)) node.spouses.push(spouse);
//             });
//           }  
//           break;

//         case "Brother":
//         case "Sister":

//           if (!mainNode.siblings.includes(node)) mainNode.siblings.push(node);
//           if (!node.siblings.includes(mainNode)) node.siblings.push(mainNode);

//           mainNode.parents.forEach((parent) => {
//             if (!node.parents.includes(parent)) node.parents.push(parent);
//             if (!parent.children.includes(node)) parent.children.push(node);
//           });

//           break;

//         case "Brother-in-law":
//         case "Sister-in-law":

//           mainNode.spouses.forEach((spouse) => {
//             if (!spouse.siblings.includes(node)) spouse.siblings.push(node);
//             if (!node.siblings.includes(spouse)) node.siblings.push(spouse);
//           });
//           break;

//         case "Son":
//         case "Daughter":
//           if (!mainNode.children.includes(node)) mainNode.children.push(node);
//           if (!node.parents.includes(mainNode)) node.parents.push(mainNode);

//           mainNode.spouses.forEach((spouse) => {
//             if (!spouse.children.includes(node)) spouse.children.push(node);
//             if (!node.parents.includes(spouse)) node.parents.push(spouse);
//           });
//           break;

//         case "Uncle":
//         case "Aunt":
//           // Connect Self and Parent's Sibling (Uncle/Aunt)
//           mainNode.parents.forEach((parent) => {
//             if (!parent.siblings.includes(node)) parent.siblings.push(node);
//             if (!node.siblings.includes(parent)) node.siblings.push(parent);
//             // Ensure the uncle/aunt is connected to Self's parents
//             if (!mainNode.siblings.includes(node)) mainNode.siblings.push(node);
//           });
//           break;

//         default:
//           break;
//       }
//     });

//     // Convert nodeMap to an array for rendering
//     const nodesArray = Object.values(nodeMap);
//     const mainNode = nodeMap[form.user_id];
//     if (mainNode && !nodesArray.includes(mainNode)) {
//       nodesArray.unshift(mainNode);
//     }

//     console.log("Final Family Tree Nodes:", nodesArray);
//     setNodes(nodesArray);
//   }, [form, familyInformation]);

//   useEffect(() => {
//     if (form.user_id && familyInformation.data.length > 0) {
//       mapFamilyTree();
//     }
//   }, [form, familyInformation, mapFamilyTree]);

//   return (
//       <React.Fragment>
//         <div className="page-content">
//           <Container fluid>
//             <div className="page-title-box">
//               <h4 className="font-size-18">EXTENDED FAMILY</h4>
//               <ol className="breadcrumb mb-0">
//                 <li className="breadcrumb-item">
//                   <a href="/">Account Info</a>
//                 </li>
//                 <li className="breadcrumb-item">
//                   <a href="/">Extended Family</a>
//                 </li>
//               </ol>
//             </div>

//             <div className="card">
//               <div className="card-body">
//                 <div className="row">
//                   <h4 className="font-size-18">Extended Family Tree</h4>
//                   <div>
//                     <Link to="/family-tree" className="btn btn-danger my-2">
//                       Back to Family Tree
//                     </Link>
//                   </div>

//                   {error && <div className="alert alert-danger">{error}</div>}

//                   {!error && nodes?.length > 0 && (
//                       <div
//                           style={{
//                             width: "100%",
//                             height: "70vh",
//                             overflow: "auto",
//                             backgroundColor: "#f8f9fa",
//                             borderRadius: "10px",
//                             position: "relative",
//                           }}
//                       >
//                         <div
//                             style={{
//                               width: "max-content",
//                               height: "max-content",
//                               padding: "20px",
//                             }}
//                         >
//                           <ReactFamilyTree
//                               nodes={nodes}
//                               rootId={rootId}
//                               width={WIDTH}
//                               height={HEIGHT}
//                               renderNode={(node) =>
//                                   node.placeholder ? null : (
//                                       <ExtendedFamilyNode
//                                           key={node.id}
//                                           node={node}
//                                           isRoot={userId !== rootId}
//                                           style={{
//                                             width: WIDTH,
//                                             height: HEIGHT,
//                                             transform: `translate(${node.left * (WIDTH / 2)
//                                             }px, ${node.top * (HEIGHT / 2)}px)`,
//                                           }}
//                                       />
//                                   )
//                               }
//                           />
//                         </div>
//                       </div>
//                   )}
//                 </div>
//               </div>
//             </div>
//           </Container>
//         </div>
//       </React.Fragment>
//   );
// };

// export default ExtendedFamily;

//  using organizational Chart

// import { userId } from "helpers/userId";
// import { Link } from "react-router-dom";
// import React, { useEffect, useState, useCallback } from "react";
// import { Container } from "reactstrap";
// import ExtendedFamilyNode from "./ExtendedFamilyNode";
// import { Tree, TreeNode } from "react-organizational-chart";

// const initialFormDetails = {
//   user_name: "",
//   user_fname: "",
//   user_mname: "",
//   user_lname: "",
//   gender: "",
//   profile_pic: "",
//   relation: "",
// };

// const ExtendedFamily = () => {
//   const [familyInformation, setFamilyInformation] = useState({ data: [] });
//   const [nodes, setNodes] = useState([]);
//   const [form, setForm] = useState(initialFormDetails);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch(
//           `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/family_information?user_id=${userId}`
//         );
//         if (!response.ok) {
//           throw new Error(
//             `Error fetching family information: ${response.statusText}`
//           );
//         }
//         const data = await response.json();
//         setFamilyInformation({ data: data?.data || [] });
//       } catch (error) {
//         console.error("Error fetching family information:", error);
//         setError("Failed to load family information. Please try again later.");
//       }
//     };

//     const fetchSelfData = async () => {
//       try {
//         const response = await fetch(
//           `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/get_personal_info?user_id=${userId}`
//         );
//         if (!response.ok) {
//           throw new Error(
//             `Error fetching personal information: ${response.statusText}`
//           );
//         }
//         const data = await response.json();
//         if (data?.success) {
//           const personalInfo = data.data;
//           const decryptedUserName = `${personalInfo.user_fname || ""} ${personalInfo.user_lname || ""}`.trim();

//           setForm({
//             ...form,
//             user_id: personalInfo.user_id.toString(),
//             user_key: personalInfo.user_key,
//             user_name: decryptedUserName,
//             user_fname: personalInfo.user_fname,
//             user_mname: personalInfo.user_mname,
//             user_lname: personalInfo.user_lname,
//             gender: personalInfo.gender,
//             profile_pic: personalInfo.profile_pic,
//             relation: "self",
//             associated: "yes",
//           });
//         } else {
//           throw new Error(
//             "Failed to load self-data. Check your profile information."
//           );
//         }
//       } catch (error) {
//         console.error("Error fetching personal information:", error);
//         setError("Failed to load personal information. Please try again later.");
//       }
//     };

//     fetchData();
//     fetchSelfData();
//   }, []);

//   const mapFamilyTree = useCallback(() => {
//     const nodeMap = {};

//     // Create the root node (self)
//     if (form.user_id > 0) {
//       nodeMap[form.user_id] = {
//         id: form.user_id.toString(),
//         gender: form.gender,
//         profilePic: form.profile_pic || "",
//         displayName: form.user_name,
//         relation: "Self",
//         associated: form.associated,
//         parents: [],
//         siblings: [],
//         spouses: [],
//         children: [],
//         inLaws: [],
//         spouseSiblings: [],
//       };
//     }

//     // Create nodes for all family members
//     familyInformation.data.forEach((person) => {
//       const gender = ["Mother", "Sister", "Daughter", "Mother-in-law", "Sister-in-law", "Aunt"].includes(person.ac_reliation)
//         ? "female"
//         : ["Father", "Brother", "Son", "Spouse", "Father-in-law", "Brother-in-law", "Uncle"].includes(person.ac_reliation)
//           ? "male"
//           : "unknown";

//       nodeMap[person.ac_id] = {
//         id: person.ac_id.toString(),
//         gender,
//         profilePic: person.ac_image || "",
//         displayName: `${person.ac_fname} ${person.ac_lname}`,
//         relation: person.ac_reliation,
//         associated: person.Associated,
//         parents: [],
//         siblings: [],
//         spouses: [],
//         children: [],
//         inLaws: [],
//         spouseSiblings: [],
//       };
//     });

//     // Map relationships
//     familyInformation.data.forEach((person) => {
//       const node = nodeMap[person.ac_id];
//       const mainNode = nodeMap[form.user_id];

//       if (!node || !mainNode) return;

//       switch (person.ac_reliation) {
//         case "Father":
//         case "Mother":
//           mainNode.parents.push(node.id);
//           node.children.push(mainNode.id);
//           break;

//         case "Brother":
//         case "Sister":
//           mainNode.siblings.push(node.id);
//           node.siblings.push(mainNode.id);
//           break;

//         case "Spouse":
//           mainNode.spouses.push(node.id);
//           node.spouses.push(mainNode.id);
//           break;

//         case "Son":
//         case "Daughter":
//           mainNode.children.push(node.id);
//           node.parents.push(mainNode.id);
//           break;

//         case "Father-in-law":
//         case "Mother-in-law":
//           mainNode.spouses.forEach((spouseId) => {
//             const spouse = nodeMap[spouseId];
//             if (spouse) {
//               spouse.inLaws.push(node.id); 
//               node.children.push(spouse.id);
//             }
//           });
//           break;

//         case "Brother-in-law":
//         case "Sister-in-law":
//           mainNode.spouses.forEach((spouseId) => {
//             const spouse = nodeMap[spouseId];
//             if (spouse) {
//               spouse.spouseSiblings.push(node.id);
//               node.spouseSiblings.push(spouse.id); 
//             }
//           });
//           break;

//         default:
//           break;
//       }
//     });

//     Object.values(nodeMap).forEach((node) => {
//       node.parents = node.parents.map((id) => nodeMap[id]);
//       node.siblings = node.siblings.map((id) => nodeMap[id]);
//       node.spouses = node.spouses.map((id) => nodeMap[id]);
//       node.children = node.children.map((id) => nodeMap[id]);
//       node.inLaws = node.inLaws.map((id) => nodeMap[id]);
//       node.spouseSiblings = node.spouseSiblings.map((id) => nodeMap[id]);
//     });

//     setNodes(Object.values(nodeMap));
//   }, [form, familyInformation]);

//   useEffect(() => {
//     if (form.user_id && familyInformation.data.length > 0) {
//       mapFamilyTree();
//     }
//   }, [form, familyInformation, mapFamilyTree]);


//   const renderTree = (node, renderedNodes = new Set()) => {
//     if (!node || renderedNodes.has(node.id)) {
//       return null;
//     }
//     renderedNodes.add(node.id);

//     return (
//       <TreeNode

//         key={node.id}
//         label={
//           <div>
//             <ExtendedFamilyNode key={node.id} node={node} />
//           </div>
//         }
//       >
//         {node.children.map((child) => renderTree(child))}
//       </TreeNode>
//     );

//   };

//   const rootNode = nodes.find((node) => node.relation === "Self");

//   return (
//     <React.Fragment>
//       <div className="page-content">
//         <Container fluid>
//           {error && <div className="alert alert-danger">{error}</div>}
//           <div className="page-title-box">
//             <h4 className="font-size-18">FAMILY TREE</h4>
//             <ol className="breadcrumb mb-0">
//               <li className="breadcrumb-item">
//                 <a href="/">Account Info</a>
//               </li>
//               <li className="breadcrumb-item">
//                 <a href="/">Family Tree</a>
//               </li>
//             </ol>
//           </div>

//           <div className="card">
//             <div className="card-body">
//               <h4 className="font-size-18">Extended Family Tree</h4>
//               <a className="btn btn-danger my-2" href="extended-family">
//                 back to Family Tree
//               </a>

//               {rootNode ? (
//                 <div className="d-flex justify-content-center">
//                   <Tree
//                     lineWidth={"2px"}
//                     lineColor={"#ccc"}
//                     lineBorderRadius={"10px"}
//                     nodeRadius={"10px"}
//                     label={
//                       <div className="d-flex justify-content-center align-items-center">

//                         {rootNode.parents?.map((parent) => (
//                           <ExtendedFamilyNode key={parent.id} node={parent} />
//                         ))}
//                         {/* Parents of Spouse */}
//                         {rootNode.spouses?.[0]?.parents?.map((parent) => (
//                           <ExtendedFamilyNode key={parent.id} node={parent} />
//                         ))}
//                       </div>
//                     }
//                   >
//                         {/* {nodes.map((node) => renderTree(node))} */}

//                     <TreeNode>
//                       {renderTree(rootNode)}
//                       {rootNode.spouses?.map((spouse) => (
//                         <React.Fragment key={spouse.id}>
//                           {renderTree(spouse)}
//                         </React.Fragment>
//                       ))}
//                     </TreeNode>

//                     {rootNode.siblings?.length > 0 && (
//                       <TreeNode>
//                         {rootNode.siblings?.map((sibling) => (
//                           <TreeNode key={sibling.id}>
//                             {renderTree(sibling)}
//                           </TreeNode>
//                         ))}
//                       </TreeNode>
//                     )}

//                     {/* Siblings of Spouse (Brother-in-law, Sister-in-law) */}
//                     {rootNode.spouses?.[0]?.siblings?.length > 0 && (
//                       <TreeNode>
//                         {rootNode.spouses[0].siblings?.map((sibling) => (
//                           <TreeNode key={sibling.id}>
//                             {renderTree(sibling)}
//                           </TreeNode>
//                         ))}
//                       </TreeNode>
//                     )}
//                   </Tree>
//                 </div>
//               ) : (
//                 <div className="alert alert-info">No family data available.</div>
//               )}
//             </div>
//           </div>
//         </Container>
//       </div>
//     </React.Fragment>
//   );

// };
// export default ExtendedFamily;


// custom family tree 

import React, { useEffect, useState, useCallback, useRef, Children } from "react";
import { Container } from "reactstrap";
import { Link } from "react-router-dom";
import { userId } from "helpers/userId";
import ExtendedFamilyNode from "./ExtendedFamilyNode";
import styles from './ExtendedFamily.module.css';
import LineBar from "pages/AllCharts/echart/linebarchart";

const initialFormDetails = {
  user_name: "",
  user_fname: "",
  user_mname: "",
  user_lname: "",
  gender: "",
  profile_pic: "",
  relation: "",
};

const ExtendedFamily = () => {
  const [familyInformation, setFamilyInformation] = useState({ data: [] });
  const [form, setForm] = useState(initialFormDetails);
  const [error, setError] = useState(null);
  const [nodes, setNodes] = useState([]);
  const nodeRefs = useRef({});
  const svgRef = useRef(null);
  const [nodesRendered, setNodesRendered] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/family_information?user_id=${userId}`,
        );
        if (!response.ok) {
          throw new Error(
            `Error fetching family information: ${response.statusText}`,
          );
        }
        const data = await response.json();
        setFamilyInformation({ data: data?.data || [] });
      } catch (error) {
        console.error("Error fetching family information:", error);
        setError("Failed to load family information. Please try again later.");
      }
    };

    const fetchSelfData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/get_personal_info?user_id=${userId}`,
        );
        if (!response.ok) {
          throw new Error(
            `Error fetching personal information: ${response.statusText}`,
          );
        }
        const data = await response.json();
        if (data?.success) {
          const personalInfo = data.data;
          const decryptedUserName = `${personalInfo.user_fname || ""} ${personalInfo.user_lname || ""
            }`.trim();

          setForm({
            ...form,
            user_id: personalInfo.user_id.toString(),
            user_key: personalInfo.user_key,
            user_name: decryptedUserName,
            user_fname: personalInfo.user_fname,
            user_mname: personalInfo.user_mname,
            user_lname: personalInfo.user_lname,
            gender: personalInfo.gender,
            profile_pic: personalInfo.profile_pic || "", // Fallback for missing profile_pic
            relation: "self",
            associated: "yes",
          });
        } else {
          throw new Error(
            "Failed to load self-data. Check your profile information.",
          );
        }
      } catch (error) {
        console.error("Error fetching personal information:", error);
        setError("Failed to load personal information. Please try again later.");
      }
    };

    fetchData();
    fetchSelfData();
  }, []);

  useEffect(() => {
    if (nodes.length > 0) {
      setNodesRendered(true);
    }
  }, [nodes]);


  const mapFamilyTree = useCallback(() => {
    const nodeMap = {};

    if (form.user_id > 0) {
      nodeMap[form.user_id] = {
        id: form.user_id.toString(),
        gender: form.gender,
        profilePic: form.profile_pic || "",
        displayName: form.user_name,
        relation: "Self",
        associated: form.associated,
        parents: [],
        siblings: [],
        spouses: [],
        children: [],
        inLaws: [],
        spouseSiblings: [],
      };
    }

    familyInformation.data.forEach((person) => {
      const gender = [
        "Mother", "Sister", "Daughter", "Mother-in-law", "Sister-in-law", "Aunt"
      ].includes(person.ac_reliation)
        ? "female"
        : [
          "Father", "Brother", "Son", "Spouse", "Father-in-law", "Brother-in-law", "Uncle"
        ].includes(person.ac_reliation)
          ? "male"
          : "unknown";

      nodeMap[person.ac_id] = {
        id: person.ac_id.toString(),
        gender: gender,
        profilePic: person.ac_image || "",
        displayName: `${person.ac_fname} ${person.ac_lname}`,
        relation: person.ac_reliation,
        associated: person.Associated,
        parents: [],
        siblings: [],
        spouses: [],
        children: [],
        inLaws: [],
        spouseSiblings: [],
        unclesAunts: [],

      };
    });

    familyInformation.data.forEach((person) => {
      const node = nodeMap[person.ac_id];
      const mainNode = nodeMap[form.user_id];
      if (!node || !mainNode) return; // Skip if nodes are not found

      switch (person.ac_reliation) {
        case "Spouse":
          if (!mainNode.spouses.includes(node)) mainNode.spouses.push(node);
          if (!node.spouses.includes(mainNode)) node.spouses.push(mainNode);
          break;

        case "Father":
        case "Mother":
          if (!mainNode.parents.includes(node)) mainNode.parents.push(node);
          if (!node.children.includes(mainNode)) node.children.push(mainNode);
          break;

        case "Father-in-law":
        case "Mother-in-law":
          if (mainNode.spouses.length > 0) {
            mainNode.spouses.forEach((spouse) => {
              if (!spouse.inLaws.includes(node)) spouse.inLaws.push(node);
              if (!node.spouses.includes(spouse)) node.spouses.push(spouse);
            });
          }
          break;

        case "Brother":
        case "Sister":
          if (!mainNode.siblings.includes(node)) mainNode.siblings.push(node);
          if (!node.siblings.includes(mainNode)) node.siblings.push(mainNode);
          break;

        case "Brother-in-law":
        case "Sister-in-law":
          if (mainNode.spouses.length > 0) {
            mainNode.spouses.forEach((spouse) => {
              if (!spouse.spouseSiblings.includes(node)) spouse.spouseSiblings.push(node);
              if (!node.spouseSiblings.includes(spouse)) node.spouseSiblings.push(spouse);
            });
          }
          break;

        case "Uncle":
        case "Aunt":
          if (mainNode.parents.length > 0) {
            mainNode.parents.forEach((parent) => {
              if (!parent.siblings.includes(node)) parent.siblings.push(node);
              if (!node.siblings.includes(parent)) node.siblings.push(parent);
            });
          }
          break;

        case "Son":
        case "Daughter":
          if (!mainNode.children.includes(node)) mainNode.children.push(node);
          if (!node.parents.includes(mainNode)) node.parents.push(mainNode);
          break;

        default:
          break;
      }
    });

    const nodesArray = Object.values(nodeMap);
    const mainNode = nodeMap[form.user_id];
    if (mainNode && !nodesArray.includes(mainNode)) {
      nodesArray.unshift(mainNode);
    }

    console.log("Final Family Tree Nodes:", nodesArray);
    setNodes(nodesArray);
  }, [form, familyInformation]);


  useEffect(() => {
    if (form.user_id && familyInformation.data.length > 0) {
      mapFamilyTree();
    }
  }, [form, familyInformation, mapFamilyTree]);


  const renderConnection = (fromId, toId, relation) => {
    const fromNode = nodeRefs.current[fromId];
    const toNode = nodeRefs.current[toId];

    if (!fromNode || !toNode) {
      console.error(`Missing node refs for fromId: ${fromId} or toId: ${toId}`);
      return null;
    }

    const fromRect = fromNode.getBoundingClientRect();
    const toRect = toNode.getBoundingClientRect();

    const svgRect = svgRef.current.getBoundingClientRect();

    const fromX = fromRect.left + fromRect.width / 2 - svgRect.left;
    const fromY = fromRect.top + fromRect.height / 2 - svgRect.top;
    const toX = toRect.left + toRect.width / 2 - svgRect.left;
    const toY = toRect.top + toRect.height / 2 - svgRect.top;

    const strokeColor = "#000";
    const strokeWidth = "0.4";

    return (
      <line
        x1={fromX}
        y1={fromY}
        x2={toX}
        y2={toY}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />

    );
  };

const renderFamilyTree = () => {
  const self = nodes.find((node) => node.relation === "Self");
  const spouse = nodes.find((node) => node.relation === "Spouse");
  const parents = nodes.filter(
    (node) => node.relation === "Father" || node.relation === "Mother"
  );
  const inLaws = nodes.filter(
    (node) => node.relation === "Father-in-law" || node.relation === "Mother-in-law"
  );
  const siblings = nodes.filter(
    (node) => node.relation === "Brother" || node.relation === "Sister"
  );
  const children = nodes.filter(
    (node) => node.relation === "Son" || node.relation === "Daughter"
  );
  const spouseSiblings = nodes.filter(
    (node) => node.relation === "Brother-in-law" || node.relation === "Sister-in-law"
  );
  const unclesAunts = nodes.filter(
    (node) => node.relation === "Uncle" || node.relation === "Aunt"
  );


  const childrenCount = children.length;

  //CenterLine

  if (childrenCount === 1) {
    const calculateConnectionLineHeight = (childrenCount) => {
      const baseHeight = 0.4;
      return childrenCount * baseHeight;
    };
    const connectionLineHeight = calculateConnectionLineHeight(childrenCount);

    return (
      <div className={styles.familyTreeContainer}>
        <div className={styles.familyTree}>
          <svg
            ref={svgRef}
            className={styles.connections}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              pointerEvents: "none",
            }}
          >
            {nodesRendered && (
              <>
                {self &&
                  parents.map((parent) =>
                    renderConnection(self.id, parent.id, parent.relation)
                  )}
                {self && spouse && renderConnection(self.id, spouse.id, "Spouse")}
                {spouse &&
                  inLaws.map((inLaw) =>
                    renderConnection(spouse.id, inLaw.id, inLaw.relation)
                  )}
                {self &&
                  siblings.map((sibling) =>
                    renderConnection(self.id, sibling.id, sibling.relation)
                  )}
                {spouse &&
                  spouseSiblings.map((sibling) =>
                    renderConnection(spouse.id, sibling.id, sibling.relation)
                  )}
              </>
            )}
          </svg>

          <div className={`${styles.level} ${styles.parentsInLaws}`}>
            {parents.map((parent) => (
              <div key={parent.id} ref={(el) => (nodeRefs.current[parent.id] = el)}>
                <ExtendedFamilyNode node={parent} />
              </div>
            ))}
            {inLaws.map((inLaw) => (
              <div key={inLaw.id} ref={(el) => (nodeRefs.current[inLaw.id] = el)}>
                <ExtendedFamilyNode node={inLaw} />
              </div>
            ))}
            {unclesAunts.map((uncleAunt) => (
              <div key={uncleAunt.id} ref={(el) => (nodeRefs.current[uncleAunt.id] = el)}>
                <ExtendedFamilyNode node={uncleAunt} />
              </div>
            ))}
          </div>

          <div className={`${styles.level} ${styles.selfSpouseSiblings}`}>
            {siblings.map((sibling) => (
              <div key={sibling.id} ref={(el) => (nodeRefs.current[sibling.id] = el)}>
                <ExtendedFamilyNode node={sibling} />
              </div>
            ))}
            {self && (
              <div key={self.id} ref={(el) => (nodeRefs.current[self.id] = el)}>
                <ExtendedFamilyNode node={self} />
              </div>
            )}
            {spouse && (
              <div key={spouse.id} ref={(el) => (nodeRefs.current[spouse.id] = el)}>
                <ExtendedFamilyNode node={spouse} />
              </div>
            )}
            {spouseSiblings.map((Spsibling) => (
              <div key={Spsibling.id} ref={(el) => (nodeRefs.current[Spsibling.id] = el)}>
                <ExtendedFamilyNode node={Spsibling} />
              </div>
            ))}
          </div>

          <div className={`${styles.level} ${styles.children}`}>
            {children.map((child) => (
              <div key={child.id} ref={(el) => (nodeRefs.current[child.id] = el)}>
                <ExtendedFamilyNode node={child} />
              </div>
            ))}
          </div>

          <div
            className={styles.connectionLine}
            style={{
              width: `${connectionLineHeight}px`,
            }}
          ></div>

          <div className={styles.connectionLineVertical}></div>

          <div
            className={styles.connectionLine}
            style={{
              width: `${connectionLineHeight}px`,
            }}
          ></div>

          <div className={styles.connectionLineVertical}></div>
        </div>
      </div>
    );
  }

  if (childrenCount === 2) {

    const calculateConnectionLineWidth = (childrenCount) => {
      const baseWidth = 135;
      const spacing = 40;
      return childrenCount * baseWidth + (childrenCount - 1) * spacing;
    };
    const calculateConnectionLineLeftPosition = (childrenCount) => {
      const baseWidth = 137;
      const spacing = 45;
      return (childrenCount - 1) * (baseWidth + spacing) / 2;
    };
  
    const calculateConnectionLineRightPosition = (childrenCount) => {
      return calculateConnectionLineLeftPosition(childrenCount);
    };
    
    const calculateConnectionLineHeight = (childrenCount) => {
      const baseHeight = 8;
      return childrenCount * baseHeight;
    };

    const connectionLineWidth = calculateConnectionLineWidth(childrenCount);
    const connectionLineLeftPosition = calculateConnectionLineLeftPosition(childrenCount);
    const connectionLineRightPosition = calculateConnectionLineRightPosition(childrenCount);
    const connectionLineHeight = calculateConnectionLineHeight(childrenCount);

    return (
      <div className={styles.familyTreeContainer}>
        <div className={styles.familyTree}>
          <svg
            ref={svgRef}
            className={styles.connections}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              pointerEvents: "none",
            }}
          >
            {nodesRendered && (
              <>
                {self &&
                  parents.map((parent) =>
                    renderConnection(self.id, parent.id, parent.relation)
                  )}
                {self && spouse && renderConnection(self.id, spouse.id, "Spouse")}
                {spouse &&
                  inLaws.map((inLaw) =>
                    renderConnection(spouse.id, inLaw.id, inLaw.relation)
                  )}
                {self &&
                  siblings.map((sibling) =>
                    renderConnection(self.id, sibling.id, sibling.relation)
                  )}
                {spouse &&
                  spouseSiblings.map((sibling) =>
                    renderConnection(spouse.id, sibling.id, sibling.relation)
                  )}
              </>
            )}
          </svg>

          <div className={`${styles.level} ${styles.parentsInLaws}`}>
            {parents.map((parent) => (
              <div key={parent.id} ref={(el) => (nodeRefs.current[parent.id] = el)}>
                <ExtendedFamilyNode node={parent} />
              </div>
            ))}
            {inLaws.map((inLaw) => (
              <div key={inLaw.id} ref={(el) => (nodeRefs.current[inLaw.id] = el)}>
                <ExtendedFamilyNode node={inLaw} />
              </div>
            ))}
            {unclesAunts.map((uncleAunt) => (
              <div key={uncleAunt.id} ref={(el) => (nodeRefs.current[uncleAunt.id] = el)}>
                <ExtendedFamilyNode node={uncleAunt} />
              </div>
            ))}
          </div>

          <div className={`${styles.level} ${styles.selfSpouseSiblings}`}>
            {siblings.map((sibling) => (
              <div key={sibling.id} ref={(el) => (nodeRefs.current[sibling.id] = el)}>
                <ExtendedFamilyNode node={sibling} />
              </div>
            ))}
            {self && (
              <div key={self.id} ref={(el) => (nodeRefs.current[self.id] = el)}>
                <ExtendedFamilyNode node={self} />
              </div>
            )}
            {spouse && (
              <div key={spouse.id} ref={(el) => (nodeRefs.current[spouse.id] = el)}>
                <ExtendedFamilyNode node={spouse} />
              </div>
            )}
            {spouseSiblings.map((Spsibling) => (
              <div key={Spsibling.id} ref={(el) => (nodeRefs.current[Spsibling.id] = el)}>
                <ExtendedFamilyNode node={Spsibling} />
              </div>
            ))}
          </div>

          <div className={`${styles.level} ${styles.children}`}>
            {children.map((child) => (
              <div key={child.id} ref={(el) => (nodeRefs.current[child.id] = el)}>
                <ExtendedFamilyNode node={child} />
              </div>
            ))}
          </div>

          <div
            className={styles.connectionLineHorizontalChildren}
            style={{
              width: `${connectionLineWidth}px`,
            }}
          ></div>

          <div className={styles.connectionLineVertical}></div>

          <div
            className={styles.connectionLineHorizontalChildren}
            style={{
              width: `${connectionLineWidth}px`,
            }}
          ></div>

          <div className={styles.connectionLineVertical}></div>

          <div
            className={styles.connectionLineLeft}
            style={{
              left: `calc(46.3% - ${connectionLineLeftPosition}px)`,
              height: "53px",
            }}
          ></div>

          <div
            className={styles.connectionLineRight}
            style={{
              left: `calc(53.6% + ${connectionLineRightPosition}px)`,
              height: "50px",
            }}
          ></div>
        </div>
      </div>
    );
  }

  if(childrenCount === 3){

    const calculateConnectionLineWidth = (childrenCount) => {
      const baseWidth = 135;
      const spacing = 100;
      return childrenCount * baseWidth + (childrenCount - 1) * spacing;
    };
  
    const calculateConnectionLineLeftPosition = (childrenCount) => {
      const baseWidth = 137;
      const spacing = 100;
      return (childrenCount - 1) * (baseWidth + spacing) / 2;
    };
  
    const calculateConnectionLineRightPosition = (childrenCount) => {
      return calculateConnectionLineLeftPosition(childrenCount);
    };
  
    const calculateConnectionLineHeight = (childrenCount) => {
      const baseHeight = 8;
      return childrenCount * baseHeight;
    };
    const connectionLineWidth = calculateConnectionLineWidth(childrenCount);
    const connectionLineLeftPosition = calculateConnectionLineLeftPosition(childrenCount);
    const connectionLineRightPosition = calculateConnectionLineRightPosition(childrenCount);
    const connectionLineHeight = calculateConnectionLineHeight(childrenCount);

    return (
      <div className={styles.familyTreeContainer}>
        <div className={styles.familyTree}>
          <svg
            ref={svgRef}
            className={styles.connections}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              pointerEvents: "none",
            }}
          >
            {nodesRendered && (
              <>
                {self &&
                  parents.map((parent) =>
                    renderConnection(self.id, parent.id, parent.relation)
                  )}
                {self && spouse && renderConnection(self.id, spouse.id, "Spouse")}
                {spouse &&
                  inLaws.map((inLaw) =>
                    renderConnection(spouse.id, inLaw.id, inLaw.relation)
                  )}
                {self &&
                  siblings.map((sibling) =>
                    renderConnection(self.id, sibling.id, sibling.relation)
                  )}
                {spouse &&
                  spouseSiblings.map((sibling) =>
                    renderConnection(spouse.id, sibling.id, sibling.relation)
                  )}
              </>
            )}
          </svg>

          <div className={`${styles.level} ${styles.parentsInLaws}`}>
            {parents.map((parent) => (
              <div key={parent.id} ref={(el) => (nodeRefs.current[parent.id] = el)}>
                <ExtendedFamilyNode node={parent} />
              </div>
            ))}
            {inLaws.map((inLaw) => (
              <div key={inLaw.id} ref={(el) => (nodeRefs.current[inLaw.id] = el)}>
                <ExtendedFamilyNode node={inLaw} />
              </div>
            ))}
            {unclesAunts.map((uncleAunt) => (
              <div key={uncleAunt.id} ref={(el) => (nodeRefs.current[uncleAunt.id] = el)}>
                <ExtendedFamilyNode node={uncleAunt} />
              </div>
            ))}
          </div>

          <div className={`${styles.level} ${styles.selfSpouseSiblings}`}>
            {siblings.map((sibling) => (
              <div key={sibling.id} ref={(el) => (nodeRefs.current[sibling.id] = el)}>
                <ExtendedFamilyNode node={sibling} />
              </div>
            ))}
            {self && (
              <div key={self.id} ref={(el) => (nodeRefs.current[self.id] = el)}>
                <ExtendedFamilyNode node={self} />
              </div>
            )}
            {spouse && (
              <div key={spouse.id} ref={(el) => (nodeRefs.current[spouse.id] = el)}>
                <ExtendedFamilyNode node={spouse} />
              </div>
            )}
            {spouseSiblings.map((Spsibling) => (
              <div key={Spsibling.id} ref={(el) => (nodeRefs.current[Spsibling.id] = el)}>
                <ExtendedFamilyNode node={Spsibling} />
              </div>
            ))}
          </div>

          <div className={`${styles.level} ${styles.children}`}>
            {children.map((child) => (
              <div key={child.id} ref={(el) => (nodeRefs.current[child.id] = el)}>
                <ExtendedFamilyNode node={child} />
              </div>
            ))}
          </div>

          <div
            className={styles.connectionLineHorizontalChildren}
            style={{
              width: `${connectionLineWidth}px`,
            }}
          >

          </div>

          <div className={styles.connectionLine}></div>

          <div
            className={styles.connectionLineHorizontalChildren}
            style={{
              width: `${connectionLineWidth}px`,
            }}
          ></div>

          <div className={styles.connectionLine}></div>

          <div
            className={styles.connectionLineLeft}
            style={{
              left: `calc(46.3% - ${connectionLineLeftPosition}px)`,
              height: "53px",
            }}
          ></div>

          <div
            className={styles.connectionLineRight}
            style={{
              left: `calc(53.6% + ${connectionLineRightPosition}px)`,
              height: "50px",
            }}
          ></div>
        </div>
      </div>
    );
  }

  if (childrenCount === 4) {
    const calculateConnectionLineWidth = (childrenCount) => {
      const baseWidth = 149;
      const spacing = 100;
      return childrenCount * baseWidth + (childrenCount - 1) * spacing;
    };
  
    const calculateConnectionLineLeftPosition = (childrenCount) => {
      const baseWidth = 155;
      const spacing = 100;
      return (childrenCount - 1) * (baseWidth + spacing) / 2;
    };
  
    const calculateConnectionLineRightPosition = (childrenCount) => {
      return calculateConnectionLineLeftPosition(childrenCount);
    };
  
    const calculateConnectionLineHeight = (childrenCount) => {
      const baseHeight = 8;
      return childrenCount * baseHeight;
    };
  
    const connectionLineWidth = calculateConnectionLineWidth(childrenCount);
    const connectionLineLeftPosition = calculateConnectionLineLeftPosition(childrenCount);
    const connectionLineRightPosition = calculateConnectionLineRightPosition(childrenCount);
    const connectionLineHeight = calculateConnectionLineHeight(childrenCount);
  
    // Get the middle children based on childrenCount - 2
    const middleChildrenCount = childrenCount - 2; 
    const middleChildren = children.slice(1, 1 + middleChildrenCount); 
  
    return (
      <div className={styles.familyTreeContainer}>
        <div className={styles.familyTree}>
          <svg
            ref={svgRef}
            className={styles.connections}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              pointerEvents: "none",
            }}
          >
            {nodesRendered && (
              <>
                {self &&
                  parents.map((parent) =>
                    renderConnection(self.id, parent.id, parent.relation)
                  )}
                {self && spouse && renderConnection(self.id, spouse.id, "Spouse")}
                {spouse &&
                  inLaws.map((inLaw) =>
                    renderConnection(spouse.id, inLaw.id, inLaw.relation)
                  )}
                {self &&
                  siblings.map((sibling) =>
                    renderConnection(self.id, sibling.id, sibling.relation)
                  )}
                {spouse &&
                  spouseSiblings.map((sibling) =>
                    renderConnection(spouse.id, sibling.id, sibling.relation)
                  )}
              </>
            )}
          </svg>
  
          <div className={`${styles.level} ${styles.parentsInLaws}`}>
            {parents.map((parent) => (
              <div key={parent.id} ref={(el) => (nodeRefs.current[parent.id] = el)}>
                <ExtendedFamilyNode node={parent} />
              </div>
            ))}
            {inLaws.map((inLaw) => (
              <div key={inLaw.id} ref={(el) => (nodeRefs.current[inLaw.id] = el)}>
                <ExtendedFamilyNode node={inLaw} />
              </div>
            ))}
            {unclesAunts.map((uncleAunt) => (
              <div key={uncleAunt.id} ref={(el) => (nodeRefs.current[uncleAunt.id] = el)}>
                <ExtendedFamilyNode node={uncleAunt} />
              </div>
            ))}
          </div>
  
          <div className={`${styles.level} ${styles.selfSpouseSiblings}`}>
            {siblings.map((sibling) => (
              <div key={sibling.id} ref={(el) => (nodeRefs.current[sibling.id] = el)}>
                <ExtendedFamilyNode node={sibling} />
              </div>
            ))}
            {self && (
              <div key={self.id} ref={(el) => (nodeRefs.current[self.id] = el)}>
                <ExtendedFamilyNode node={self} />
              </div>
            )}
            {spouse && (
              <div key={spouse.id} ref={(el) => (nodeRefs.current[spouse.id] = el)}>
                <ExtendedFamilyNode node={spouse} />
              </div>
            )}
            {spouseSiblings.map((Spsibling) => (
              <div key={Spsibling.id} ref={(el) => (nodeRefs.current[Spsibling.id] = el)}>
                <ExtendedFamilyNode node={Spsibling} />
              </div>
            ))}
          </div>
  
          <div className={`${styles.level} ${styles.children}`}>
            {children.map((child) => (
              <div key={child.id} ref={(el) => (nodeRefs.current[child.id] = el)}>
                <ExtendedFamilyNode node={child} />
              </div>
            ))}
          </div>
  
          <div
            className={styles.connectionLineHorizontalChildren}
            style={{
              width: `${connectionLineWidth}px`,
            }}
          ></div>
  
          <div className={styles.connectionLineVertical}></div>
  
          <div
            className={styles.connectionLineHorizontalChildren}
            style={{
              width: `${connectionLineWidth}px`,
            }}
          ></div>
  
          <div className={styles.connectionLineVertical}></div>
  
          <div
            className={styles.connectionLineLeft}
            style={{
              left: `calc(46.3% - ${connectionLineLeftPosition}px)`,
              height: "53px",
            }}
          ></div>
  
          <div
            className={styles.connectionLineRight}
            style={{
              left: `calc(53.6% + ${connectionLineRightPosition}px)`,
              height: "50px",
            }}
          ></div>
  
          {/* Add lines for the middle children at the bottom center */}

          {middleChildren.map((child, index) => (
            <div
              key={child.id}
              className={styles.connectionLineMiddle}
              style={{
                left: `calc(${50 + (index === 0 ? 17 : 34.1)}% - ${connectionLineWidth / 2}px)`,
                bottom: "160px", 
                height: "40px",
              }}
            ></div>
          ))}
        </div>
      </div>
    );
  }


  if (childrenCount === 5) {
    const calculateConnectionLineWidth = (childrenCount) => {
      const baseWidth = 160.7;
      const spacing = 100;
      return childrenCount * baseWidth + (childrenCount - 1) * spacing;
    };
  
    const calculateConnectionLineLeftPosition = (childrenCount) => {
      const baseWidth = 167;
      const spacing = 100;
      return (childrenCount - 1) * (baseWidth + spacing) / 2;
    };
  
    const calculateConnectionLineRightPosition = (childrenCount) => {
      return calculateConnectionLineLeftPosition(childrenCount);
    };
  
    const calculateConnectionLineHeight = (childrenCount) => {
      const baseHeight = 8;
      return childrenCount * baseHeight;
    };
  
    const connectionLineWidth = calculateConnectionLineWidth(childrenCount);
    const connectionLineLeftPosition = calculateConnectionLineLeftPosition(childrenCount);
    const connectionLineRightPosition = calculateConnectionLineRightPosition(childrenCount);
    const connectionLineHeight = calculateConnectionLineHeight(childrenCount);
  
    const middleChildrenCount = childrenCount - 2; 
    const middleChildren = children.slice(1, 1 + middleChildrenCount); 
  
    return (
      <div className={styles.familyTreeContainer}>
        <div className={styles.familyTree}>
          <svg
            ref={svgRef}
            className={styles.connections}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              pointerEvents: "none",
            }}
          >
            {nodesRendered && (
              <>
                {self &&
                  parents.map((parent) =>
                    renderConnection(self.id, parent.id, parent.relation)
                  )}
                {self && spouse && renderConnection(self.id, spouse.id, "Spouse")}
                {spouse &&
                  inLaws.map((inLaw) =>
                    renderConnection(spouse.id, inLaw.id, inLaw.relation)
                  )}
                {self &&
                  siblings.map((sibling) =>
                    renderConnection(self.id, sibling.id, sibling.relation)
                  )}
                {spouse &&
                  spouseSiblings.map((sibling) =>
                    renderConnection(spouse.id, sibling.id, sibling.relation)
                  )}
              </>
            )}
          </svg>
  
          <div className={`${styles.level} ${styles.parentsInLaws}`}>
            {parents.map((parent) => (
              <div key={parent.id} ref={(el) => (nodeRefs.current[parent.id] = el)}>
                <ExtendedFamilyNode node={parent} />
              </div>
            ))}
            {inLaws.map((inLaw) => (
              <div key={inLaw.id} ref={(el) => (nodeRefs.current[inLaw.id] = el)}>
                <ExtendedFamilyNode node={inLaw} />
              </div>
            ))}
            {unclesAunts.map((uncleAunt) => (
              <div key={uncleAunt.id} ref={(el) => (nodeRefs.current[uncleAunt.id] = el)}>
                <ExtendedFamilyNode node={uncleAunt} />
              </div>
            ))}
          </div>
  
          <div className={`${styles.level} ${styles.selfSpouseSiblings}`}>
            {siblings.map((sibling) => (
              <div key={sibling.id} ref={(el) => (nodeRefs.current[sibling.id] = el)}>
                <ExtendedFamilyNode node={sibling} />
              </div>
            ))}
            {self && (
              <div key={self.id} ref={(el) => (nodeRefs.current[self.id] = el)}>
                <ExtendedFamilyNode node={self} />
              </div>
            )}
            {spouse && (
              <div key={spouse.id} ref={(el) => (nodeRefs.current[spouse.id] = el)}>
                <ExtendedFamilyNode node={spouse} />
              </div>
            )}
            {spouseSiblings.map((Spsibling) => (
              <div key={Spsibling.id} ref={(el) => (nodeRefs.current[Spsibling.id] = el)}>
                <ExtendedFamilyNode node={Spsibling} />
              </div>
            ))}
          </div>
  
          <div className={`${styles.level} ${styles.children}`}>
            {children.map((child) => (
              <div key={child.id} ref={(el) => (nodeRefs.current[child.id] = el)}>
                <ExtendedFamilyNode node={child} />
              </div>
            ))}
          </div>
  
          <div
            className={styles.connectionLineHorizontalChildren}
            style={{
              width: `${connectionLineWidth}px`,
            }}
          ></div>
  
          <div className={styles.connectionLineVertical}></div>
  
          <div
            className={styles.connectionLineHorizontalChildren}
            style={{
              width: `${connectionLineWidth}px`,
            }}
          ></div>
  
          <div className={styles.connectionLineVertical}></div>
  
          <div
            className={styles.connectionLineLeft}
            style={{
              left: `calc(46.3% - ${connectionLineLeftPosition}px)`,
              height: "53px",
            }}
          ></div>
  
          <div
            className={styles.connectionLineRight}
            style={{
              left: `calc(53.6% + ${connectionLineRightPosition}px)`,
              height: "50px",
            }}
          ></div>
  
          {/* Add lines for the middle children */}
          {middleChildren.map((child, index) => (
            <div
              key={child.id}
              className={styles.connectionLineMiddle}
              style={{
                left: `calc(${50 + (index === 0 ? 34.4 : index === 1 ? 51.5 : 17.2)}% - ${connectionLineWidth / 2}px)`, // Adjust left position for each line
                bottom: "160px", 
                height: "40px", // Adjust height as needed
              }}
            ></div>
          ))}
        </div>
      </div>
    );
  }

  if (childrenCount === 6) {
    const calculateConnectionLineWidth = (childrenCount) => {
      const baseWidth = 169;
      const spacing = 100;
      return childrenCount * baseWidth + (childrenCount - 1) * spacing;
    };
  
    const calculateConnectionLineLeftPosition = (childrenCount) => {
      const baseWidth = 177;
      const spacing = 100;
      return (childrenCount - 1) * (baseWidth + spacing) / 2;
    };
  
    const calculateConnectionLineRightPosition = (childrenCount) => {
      return calculateConnectionLineLeftPosition(childrenCount);
    };
  
    const calculateConnectionLineHeight = (childrenCount) => {
      const baseHeight = 8;
      return childrenCount * baseHeight;
    };
  
    const connectionLineWidth = calculateConnectionLineWidth(childrenCount);
    const connectionLineLeftPosition = calculateConnectionLineLeftPosition(childrenCount);
    const connectionLineRightPosition = calculateConnectionLineRightPosition(childrenCount);
    const connectionLineHeight = calculateConnectionLineHeight(childrenCount);
  
    // Get the middle children based on childrenCount - 2
    const middleChildrenCount = childrenCount - 2; // For 6 children, this will be 4
    const middleChildren = children.slice(1, 1 + middleChildrenCount); // Get middle children dynamically
  
    return (
      <div className={styles.familyTreeContainer}>
        <div className={styles.familyTree}>
          <svg
            ref={svgRef}
            className={styles.connections}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              pointerEvents: "none",
            }}
          >
            {nodesRendered && (
              <>
                {self &&
                  parents.map((parent) =>
                    renderConnection(self.id, parent.id, parent.relation)
                  )}
                {self && spouse && renderConnection(self.id, spouse.id, "Spouse")}
                {spouse &&
                  inLaws.map((inLaw) =>
                    renderConnection(spouse.id, inLaw.id, inLaw.relation)
                  )}
                {self &&
                  siblings.map((sibling) =>
                    renderConnection(self.id, sibling.id, sibling.relation)
                  )}
                {spouse &&
                  spouseSiblings.map((sibling) =>
                    renderConnection(spouse.id, sibling.id, sibling.relation)
                  )}
              </>
            )}
          </svg>
  
          <div className={`${styles.level} ${styles.parentsInLaws}`}>
            {parents.map((parent) => (
              <div key={parent.id} ref={(el) => (nodeRefs.current[parent.id] = el)}>
                <ExtendedFamilyNode node={parent} />
              </div>
            ))}
            {inLaws.map((inLaw) => (
              <div key={inLaw.id} ref={(el) => (nodeRefs.current[inLaw.id] = el)}>
                <ExtendedFamilyNode node={inLaw} />
              </div>
            ))}
            {unclesAunts.map((uncleAunt) => (
              <div key={uncleAunt.id} ref={(el) => (nodeRefs.current[uncleAunt.id] = el)}>
                <ExtendedFamilyNode node={uncleAunt} />
              </div>
            ))}
          </div>
  
          <div className={`${styles.level} ${styles.selfSpouseSiblings}`}>
            {siblings.map((sibling) => (
              <div key={sibling.id} ref={(el) => (nodeRefs.current[sibling.id] = el)}>
                <ExtendedFamilyNode node={sibling} />
              </div>
            ))}
            {self && (
              <div key={self.id} ref={(el) => (nodeRefs.current[self.id] = el)}>
                <ExtendedFamilyNode node={self} />
              </div>
            )}
            {spouse && (
              <div key={spouse.id} ref={(el) => (nodeRefs.current[spouse.id] = el)}>
                <ExtendedFamilyNode node={spouse} />
              </div>
            )}
            {spouseSiblings.map((Spsibling) => (
              <div key={Spsibling.id} ref={(el) => (nodeRefs.current[Spsibling.id] = el)}>
                <ExtendedFamilyNode node={Spsibling} />
              </div>
            ))}
          </div>
  
          <div className={`${styles.level} ${styles.children}`}>
            {children.map((child) => (
              <div key={child.id} ref={(el) => (nodeRefs.current[child.id] = el)}>
                <ExtendedFamilyNode node={child} />
              </div>
            ))}
          </div>
  
          <div
            className={styles.connectionLineHorizontalChildren}
            style={{
              width: `${connectionLineWidth}px`,
            }}
          ></div>
  
          <div className={styles.connectionLineVertical}></div>
  
          <div
            className={styles.connectionLineHorizontalChildren}
            style={{
              width: `${connectionLineWidth}px`,
            }}
          ></div>
  
          <div className={styles.connectionLineVertical}></div>
  
          <div
            className={styles.connectionLineLeft}
            style={{
              left: `calc(46.3% - ${connectionLineLeftPosition}px)`,
              height: "53px",
            }}
          ></div>
  
          <div
            className={styles.connectionLineRight}
            style={{
              left: `calc(53.6% + ${connectionLineRightPosition}px)`,
              height: "50px",
            }}
          ></div>
  
          {middleChildren.map((child, index) => (
            <div
              key={child.id}
              className={styles.connectionLineMiddle}
              style={{
                left: `calc(${50 + (index === 0 ? 51.9 : index === 1 ? 34.8 : index === 2 ? 17.5 : 68.9)}% - ${connectionLineWidth / 2}px)`, // Adjust left position for each line
                bottom: "160px", 
                height: "40px", 
              }}
            ></div>
          ))}
        </div>
      </div>
    );
  }

  if (childrenCount === 7) {
    const calculateConnectionLineWidth = (childrenCount) => {
      const baseWidth = 171.8;
      const spacing = 100;
      return childrenCount * baseWidth + (childrenCount - 1) * spacing;
    };
  
    const calculateConnectionLineLeftPosition = (childrenCount) => {
      const baseWidth = 175;
      const spacing = 100;
      return (childrenCount - 1) * (baseWidth + spacing) / 2;
    };
  
    const calculateConnectionLineRightPosition = (childrenCount) => {
      return calculateConnectionLineLeftPosition(childrenCount);
    };
  
    const calculateConnectionLineHeight = (childrenCount) => {
      const baseHeight = 8;
      return childrenCount * baseHeight;
    };
  
    const connectionLineWidth = calculateConnectionLineWidth(childrenCount);
    const connectionLineLeftPosition = calculateConnectionLineLeftPosition(childrenCount);
    const connectionLineRightPosition = calculateConnectionLineRightPosition(childrenCount);
    const connectionLineHeight = calculateConnectionLineHeight(childrenCount);
  
    // Get the middle children based on childrenCount - 2
    const middleChildrenCount = childrenCount - 2; // For 7 children, this will be 5
    const middleChildren = children.slice(1, 1 + middleChildrenCount); // Get middle children dynamically
  
    return (
      <div className={styles.familyTreeContainer}>
        <div className={styles.familyTree}>
          <svg
            ref={svgRef}
            className={styles.connections}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              pointerEvents: "none",
            }}
          >
            {nodesRendered && (
              <>
                {self &&
                  parents.map((parent) =>
                    renderConnection(self.id, parent.id, parent.relation)
                  )}
                {self && spouse && renderConnection(self.id, spouse.id, "Spouse")}
                {spouse &&
                  inLaws.map((inLaw) =>
                    renderConnection(spouse.id, inLaw.id, inLaw.relation)
                  )}
                {self &&
                  siblings.map((sibling) =>
                    renderConnection(self.id, sibling.id, sibling.relation)
                  )}
                {spouse &&
                  spouseSiblings.map((sibling) =>
                    renderConnection(spouse.id, sibling.id, sibling.relation)
                  )}
              </>
            )}
          </svg>
  
          <div className={`${styles.level} ${styles.parentsInLaws}`}>
            {parents.map((parent) => (
              <div key={parent.id} ref={(el) => (nodeRefs.current[parent.id] = el)}>
                <ExtendedFamilyNode node={parent} />
              </div>
            ))}
            {inLaws.map((inLaw) => (
              <div key={inLaw.id} ref={(el) => (nodeRefs.current[inLaw.id] = el)}>
                <ExtendedFamilyNode node={inLaw} />
              </div>
            ))}
            {unclesAunts.map((uncleAunt) => (
              <div key={uncleAunt.id} ref={(el) => (nodeRefs.current[uncleAunt.id] = el)}>
                <ExtendedFamilyNode node={uncleAunt} />
              </div>
            ))}
          </div>
  
          <div className={`${styles.level} ${styles.selfSpouseSiblings}`}>
            {siblings.map((sibling) => (
              <div key={sibling.id} ref={(el) => (nodeRefs.current[sibling.id] = el)}>
                <ExtendedFamilyNode node={sibling} />
              </div>
            ))}
            {self && (
              <div key={self.id} ref={(el) => (nodeRefs.current[self.id] = el)}>
                <ExtendedFamilyNode node={self} />
              </div>
            )}
            {spouse && (
              <div key={spouse.id} ref={(el) => (nodeRefs.current[spouse.id] = el)}>
                <ExtendedFamilyNode node={spouse} />
              </div>
            )}
            {spouseSiblings.map((Spsibling) => (
              <div key={Spsibling.id} ref={(el) => (nodeRefs.current[Spsibling.id] = el)}>
                <ExtendedFamilyNode node={Spsibling} />
              </div>
            ))}
          </div>
  
          <div className={`${styles.level} ${styles.children}`}>
            {children.map((child) => (
              <div key={child.id} ref={(el) => (nodeRefs.current[child.id] = el)}>
                <ExtendedFamilyNode node={child} />
              </div>
            ))}
          </div>
  
          <div
            className={styles.connectionLineHorizontalChildren}
            style={{
              width: `${connectionLineWidth}px`,
            }}
          ></div>
  
          <div className={styles.connectionLineVertical}></div>
  
          <div
            className={styles.connectionLineHorizontalChildren}
            style={{
              width: `${connectionLineWidth}px`,
            }}
          ></div>
  
          <div className={styles.connectionLineVertical}></div>
  
          <div
            className={styles.connectionLineLeft}
            style={{
              left: `calc(46.3% - ${connectionLineLeftPosition}px)`,
              height: "53px",
            }}
          ></div>
  
          <div
            className={styles.connectionLineRight}
            style={{
              left: `calc(53.6% + ${connectionLineRightPosition}px)`,
              height: "50px",
            }}
          ></div>
  
          {/* Add lines for the middle children */}
          {middleChildren.map((child, index) => (
            <div
              key={child.id}
              className={styles.connectionLineMiddle}
              style={{
                left: `calc(${50 + (index === 0 ? 73.2 : index === 1 ? 14.7 : index === 2 ? 29.3 : index === 3 ? 44: 58.7)}% - ${connectionLineWidth / 2}px)`, // Adjust left position for each line
                bottom: "160px", 
                height: "40px",
              }}
            ></div>
          ))}
        </div>
      </div>
    );
  }

  if (childrenCount === 8) {
    const calculateConnectionLineWidth = (childrenCount) => {
      const baseWidth = 175;
      const spacing = 100;
      return childrenCount * baseWidth + (childrenCount - 1) * spacing;
    };
  
    const calculateConnectionLineLeftPosition = (childrenCount) => {
      const baseWidth = 175;
      const spacing = 100;
      return (childrenCount - 1) * (baseWidth + spacing) / 2;
    };
  
    const calculateConnectionLineRightPosition = (childrenCount) => {
      return calculateConnectionLineLeftPosition(childrenCount);
    };
  
    const calculateConnectionLineHeight = (childrenCount) => {
      const baseHeight = 8;
      return childrenCount * baseHeight;
    };
  
    const connectionLineWidth = calculateConnectionLineWidth(childrenCount);
    const connectionLineLeftPosition = calculateConnectionLineLeftPosition(childrenCount);
    const connectionLineRightPosition = calculateConnectionLineRightPosition(childrenCount);
    const connectionLineHeight = calculateConnectionLineHeight(childrenCount);
  
    // Get the middle children based on childrenCount - 2
    const middleChildrenCount = childrenCount - 2; // For 8 children, this will be 6
    const middleChildren = children.slice(1, 1 + middleChildrenCount); // Get middle children dynamically
  
    return (
      <div className={styles.familyTreeContainer}>
        <div className={styles.familyTree}>
          <svg
            ref={svgRef}
            className={styles.connections}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              pointerEvents: "none",
            }}
          >
            {nodesRendered && (
              <>
                {self &&
                  parents.map((parent) =>
                    renderConnection(self.id, parent.id, parent.relation)
                  )}
                {self && spouse && renderConnection(self.id, spouse.id, "Spouse")}
                {spouse &&
                  inLaws.map((inLaw) =>
                    renderConnection(spouse.id, inLaw.id, inLaw.relation)
                  )}
                {self &&
                  siblings.map((sibling) =>
                    renderConnection(self.id, sibling.id, sibling.relation)
                  )}
                {spouse &&
                  spouseSiblings.map((sibling) =>
                    renderConnection(spouse.id, sibling.id, sibling.relation)
                  )}
              </>
            )}
          </svg>
  
          <div className={`${styles.level} ${styles.parentsInLaws}`}>
            {parents.map((parent) => (
              <div key={parent.id} ref={(el) => (nodeRefs.current[parent.id] = el)}>
                <ExtendedFamilyNode node={parent} />
              </div>
            ))}
            {inLaws.map((inLaw) => (
              <div key={inLaw.id} ref={(el) => (nodeRefs.current[inLaw.id] = el)}>
                <ExtendedFamilyNode node={inLaw} />
              </div>
            ))}
            {unclesAunts.map((uncleAunt) => (
              <div key={uncleAunt.id} ref={(el) => (nodeRefs.current[uncleAunt.id] = el)}>
                <ExtendedFamilyNode node={uncleAunt} />
              </div>
            ))}
          </div>
  
          <div className={`${styles.level} ${styles.selfSpouseSiblings}`}>
            {siblings.map((sibling) => (
              <div key={sibling.id} ref={(el) => (nodeRefs.current[sibling.id] = el)}>
                <ExtendedFamilyNode node={sibling} />
              </div>
            ))}
            {self && (
              <div key={self.id} ref={(el) => (nodeRefs.current[self.id] = el)}>
                <ExtendedFamilyNode node={self} />
              </div>
            )}
            {spouse && (
              <div key={spouse.id} ref={(el) => (nodeRefs.current[spouse.id] = el)}>
                <ExtendedFamilyNode node={spouse} />
              </div>
            )}
            {spouseSiblings.map((Spsibling) => (
              <div key={Spsibling.id} ref={(el) => (nodeRefs.current[Spsibling.id] = el)}>
                <ExtendedFamilyNode node={Spsibling} />
              </div>
            ))}
          </div>
  
          <div className={`${styles.level} ${styles.children}`}>
            {children.map((child) => (
              <div key={child.id} ref={(el) => (nodeRefs.current[child.id] = el)}>
                <ExtendedFamilyNode node={child} />
              </div>
            ))}
          </div>
  
          <div
            className={styles.connectionLineHorizontalChildren}
            style={{
              width: `${connectionLineWidth}px`,
            }}
          ></div>
  
          <div className={styles.connectionLineVertical}></div>
  
          <div
            className={styles.connectionLineHorizontalChildren}
            style={{
              width: `${connectionLineWidth}px`,
            }}
          ></div>
  
          <div className={styles.connectionLineVertical}></div>
  
          <div
            className={styles.connectionLineLeft}
            style={{
              left: `calc(46.3% - ${connectionLineLeftPosition}px)`,
              height: "53px",
            }}
          ></div>
  
          <div
            className={styles.connectionLineRight}
            style={{
              left: `calc(53.6% + ${connectionLineRightPosition}px)`,
              height: "50px",
            }}
          ></div>
  
          {/* Add lines for the middle children */}
          {middleChildren.map((child, index) => (
            <div
              key={child.id}
              className={styles.connectionLineMiddle}
              style={{
                left: `calc(${50 + (index === 0 ? 12.8 : index === 1 ? 76.5 : index === 2 ? 25.6 : index === 3 ? 51.1 : index === 4 ? 63.7 : 38.2)}% - ${connectionLineWidth / 2}px)`, 
                bottom: "160px", 
                height: "40px",
              }}
            ></div>
          ))}
        </div>
      </div>
    );
  }

  return null;
};

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <h4 className="font-size-18">EXTENDED FAMILY</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="/">Account Info</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/">Extended Family</a>
              </li>
            </ol>
          </div>

          <div className="card">
            <div className="card-body">
              <div className="row">
                <h4 className="font-size-18">Extended Family Tree</h4>
                <div>
                  <Link to="/family-tree" className="btn btn-danger my-2">
                    Back to Family Tree
                  </Link>
                </div>

                {error && <div className="alert alert-danger">{error}</div>}

                {!error && familyInformation.data.length > 0 && (
                  <div className="family-tree-container">
                    {renderFamilyTree()}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ExtendedFamily;


